@import '../../../assets/style/variable';

.metric-chart-box {
  .metric-chart {
    display: grid;
    grid-template-areas: "head" "chart";

    .metric-chart-top {
      grid-area: head;
      display: grid;
      grid-template-areas: "left right";
      border-bottom: 1px solid $border;
      padding: 0 15px;
      align-items: center;

      .metric-chart-top-left {
        grid-area: left;

        .dropdown {
          width: fit-content;
          line-height: inherit;

          img {
            height: 6px;
            position: relative;
            top: 1px;
            padding-left: 5px;
          }

          span {
            padding: 0;
            font-size: $f14 + 4;
            color: $black_blue;
          }
        }
      }

      .metric-chart-top-right {
        grid-area: right;
        justify-self: end;
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 20px;

        .metric-chart-right-item {
          cursor: pointer;

          &:hover {
            background: none;
            color: $pink;
          }

          &.active {
            background: none;
            color: $pink;
            font-weight: bold;
          }
        }
      }
    }

    .metric-chart-chart {
      grid-area: chart;

      .table-panel-spinner {
        align-self: center;
        height: auto;
      }
    }
  }
}
