@import '../../../../assets/style/variable';

.wizard-custom-checkbox {
  .w-c-checkbox-label {
    display: flex !important;

    .w-c-in-ex-checkbox {
      width: 50px;
      text-align: center;

      .include-exclude-check {
        color: $pink;
        border-bottom: 1px dashed $pink;
        cursor: pointer;

        &.checked {
          color: $green;
          border-bottom: 1px dashed $green;
        }
      }
    }
  }
}
