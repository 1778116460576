body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  -webkit-border-radius: 20px;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 20px;
  -webkit-border-radius: 20px;
}
