.table-confirmation {
  position: absolute;
  top: 0;
  right: 25px;
  color: #3F4D5F;
  font-size: 1.2em;
  padding: 15px;
  width: 170px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  z-index: 1;

  .title {
    text-align: center;
  }

  .btn-container {
    display: flex;
    justify-content: space-around;

    .btn {
      padding: 5px 10px;
      font-size: 12px;
    }
  }
}
