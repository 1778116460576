@import "../../../assets/style/variable.scss";

.top-filters {
  margin-bottom: 30px;
}

.dashboard-dropdown-menu {
  .dropdown-item {
    &.active {
      color: $pink;
    }
  }
}
