@import "../../assets/style/variable";

.table-columns-action-editing-box {
  width: max-content;
  height: fit-content;
  align-self: center;
  font-size: 18px;

  .editing-cancel {
    i {
      color: $red;
    }

    cursor: pointer;
    margin-bottom: 8px;
  }

  .editing-update {
    i {
      color: $green;
    }

    cursor: pointer;
  }
}

.action-editing-box {
  display: grid;
  justify-items: center;

  .table-actions-box {
    right: 0;
    left: auto;
  }
}
