
.upload-file {
    width: 100%;
    border: 1px dashed $pink;
    @include radius(8px);
    padding: 10px;
    position: relative;
    min-height: 220px;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-bottom: 20px;
    .wrap {
        // position: relative;
        // z-index: -1;
        // top: 50%;
        // -webkit-transform: translate(0, -50%);
        // -ms-transform: translate(0, -50%);
        // transform: translate(0, -50%);
    }
    .delete-img {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 10;
        color: $gray;
        font-size: $f14 + 2;
        display: none;
        cursor: pointer;
        &:hover {
            color: $pink;
        }
    }
    input[type="file"] {
        visibility: hidden;
        position: absolute;
        left: -99999px;
    }
    .before-load {
        padding: 40px 0;
        .icon-upload {
            text-align: center;
            margin: 0;
            span {
                display: block;
                font-size: $f14 + 2;
                em {
                    font-style: normal;
                    color: $pink;
                    cursor: pointer;
                }
            }
        }
        .note {
            color: $gray;
            text-align: center;
            padding: 15px 0 0 0;
            margin: 0;
        }
    }
    .after-load {
        display: none;
        padding: 20px 0;
        .upload-preview {
            margin: 0 -10px -20px 0;
            padding: 0;
            list-style: none;
            li {
                display: inline-block;
                vertical-align: middle;
                width: 152px;
                height: 152px;
                border: 1px solid $border;
                @include radius(4px);
                margin: 0 10px 20px 10px;
                position: relative;
                .delete-img {
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    font-weight: normal;
                    font-size: $f14 - 2;
                    span {
                        border: 1px solid $border;
                        display: block;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        background: $white;
                        color: $primary_color;
                        @include radius(50px);
                        line-height: 20px;
                        &:hover {
                            background: $pink;
                            border-color: $pink;
                            color: $white;
                        }
                    }
                }
                span {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    overflow: hidden;
                    img {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 100%;
                        height: auto;
                        padding: 5px;
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                    }
                }
                
            }
            
        }
    }
    &.uploaded {
        .before-load {
            display: none;
        }
        .after-load {
            display: block;
        }
        .delete-img {
            display: block;
        }
    }
    &[data="drag-over"] {
        border-color: $dark_violet!important;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 105, 132, 0.1);
        }
    }
}

@media screen and (max-width: 640px) {
    .upload-file {
        .after-load {
            .upload-preview {
                margin: 0 0 -20px 0;
                padding: 0 5px 0 0;
                li {
                    margin: 0 0 20px 0;
                    width: 100%;
                }
            }
        }
    }
}