.timeline-log-action-data-box {
  position: relative;

  .action-data-box-button {
    margin-top: 5px;
    text-decoration: underline dotted;
    cursor: pointer;
  }

  .action-data-box-popup {
    position: absolute;
    top: 105px;
    left: -45px;
    background-color: #fff;
    z-index: 1;
    width: max-content;
    height: max-content;
    max-width: 240px;
    max-height: 240px;
    overflow: auto;
    padding: 10px;
    box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
  }
}
