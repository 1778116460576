@import 'variable.scss';


html,
body {
    height: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

button,
a {
    outline: none;

    &:focus,
    &:active {
        outline: none;
    }
}

body {
    background: $body_background;
    color: $primary_color;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-size: $f14;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 320px;
}

// Overide padding column
$containerGap: 10;
$colGap: 20;
.container,
.container-fluid {
    padding-left: $containerGap;
    padding-right: $containerGap;
}
.row {
    margin-left: -($colGap / 2) * 1px;
    margin-right: -($colGap / 2) * 1px;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}
[class*="col-"] {
    float: left;
    padding-left: ($colGap / 2) * 1px;
    padding-right: ($colGap / 2) * 1px;
}

.wrap {
    display: flex;
    flex-direction: column;
}

// Tooltip chart
.metric-chart-chart, .sparkline {
    .apexcharts-tooltip {
        background: $pink!important;
        padding: 3px 8px 2px!important;
        @include radius(5px);
        border-color: $pink!important;

        span {
            color: $white;
            font-weight: bold;
        }
    }
}

// Donut chart
.donut {
    max-width: 400px;
    margin: 0 auto;
    top: 25px;
    .apexcharts-canvas {
        margin: 0 auto;
    }
    .apexcharts-pie-series {
        path {
            filter: inherit;
            &[selected="true"] {
                stroke-width: 10;
            }
        }
    }
}

.sparkline {
    margin-top: -3px;
    .apexcharts-tooltip {
        padding: 2px 5px !important;
        .apexcharts-tooltip-text-value {
            margin: 0 !important;
            padding: 0 5px !important;
            color: $white !important;
            font-weight: 500;
            font-weight: bold !important;
        }
    }
    .apexcharts-tooltip-series-group {
        display: inline-block !important;
        line-height: 1.1 !important;
        height: auto !important;
        padding: 0 !important;
    }
}

.dropdown {
    .dropdown-menu {
        margin: 1px 0 0 0;
        top: 100% !important;
        @include radius(0 0 4px 4px);
        padding: 0;
        border: none;
        font-size: $f14;
        -webkit-box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
        box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
        transform: translate3d(0px, 0px, 0px) !important;

        li {
            display: block;
        }

        a {
            color: $black_blue;
            padding: 7px 10px;
            background: none;

            img {
                display: inline-block;
                vertical-align: middle;
                height: 21px;

                &.img-active {
                    display: none;
                    visibility: hidden;
                }

                &+span {
                    padding-left: 7px;
                }
            }

            span {
                display: inline-block;
                vertical-align: middle;
            }

            &:hover {
                background: none;
                color: $pink;

                img {
                    display: none;
                    visibility: hidden;

                    &.img-active {
                        display: inline-block;
                        visibility: visible;
                    }
                }
            }

            &+a {
                border-top: 1px solid $border;
                border-color: rgba(225, 228, 234, 0.5);
            }
        }
    }

    &.dropdown-arrow-top {
        .dropdown-menu {
            @include radius(4px);
            margin: 13px 0 0 0;
            padding: 5px 0;

            &:before {
                position: absolute;
                top: -8px;
                left: 9px;
                display: inline-block;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
                border-bottom-color: transparent;
                content: '';
            }

            &:after {
                position: absolute;
                top: -10px;
                left: 10px;
                display: inline-block;
                border-right: 7px solid transparent;
                border-bottom: 13px solid $white;
                border-left: 7px solid transparent;
                content: '';
            }
        }
    }

    &.pos-right {
        left: inherit;
        right: 0;

        .dropdown-menu {
            left: inherit !important;
            right: 0;

            &:before {
                left: inherit !important;
                right: 8px;
            }

            &:after {
                left: inherit !important;
                right: 12px;
            }
        }
    }
}

.btn {

    //height: 36px;
    //padding: 5px 10px;
    font-size: $f14;
    img {
        display: inline-block;
        vertical-align: middle;
    }

    span {
        display: inline-block;
        vertical-align: middle;
        padding: 0 0 0 10px;
    }

    &:focus,
    &:active {
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none !important;
    }

    &:after {
        display: none;
    }
}

.btn-pink {
    background: $pink !important;
    color: $white !important;
    @include radius(50px);
    min-width: 100px !important;

    &:hover {
        color: $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
    }
}

.btn-pink-light {
    background: $white;
    color: $pink;
    @include radius(50px);
    min-width: 100px;
    //line-height: 0;
    border-color: $pink;

    &:hover {
        background: $pink;
        color: $white !important;
        border-color: $pink;
        opacity: 0.8;
        filter: alpha(opacity=80);
    }
}

.btn-dark-violet {
    background: $dark_violet !important;
    color: $white !important;
    @include radius(50px);
    min-width: 100px !important;
    img {
        display: inline-block !important;
        visibility: visible !important;
    }
    &:hover {
        color: $white;
        opacity: 0.8;
        filter: alpha(opacity=80);
    }
}

.btn-light-violet {
    background: $white !important;
    border: 1px solid $dark_violet;
    color: $dark_violet !important;
    @include radius(50px);
    min-width: 100px !important;

    &:hover {
        color: $white !important;
        background: $dark_violet !important;
    }
}

.btn-gray {
    background: $white;
    color: $gray;
    @include radius(50px);
    min-width: 115px;
    //line-height: 0;
    border: 1px solid $border;

    img {
        width: 14px;
    }

    &:hover {
        border: 1px solid $pink;
        color: $gray;
    }
}

input[type='text'],
input[type='tel'],
input[type='number'],
input[type='email'],
input[type='password'] {
    border: 1px solid $border;
    @include radius(50px);
    height: 36px;
    padding: 0 10px;
    width: 100%;
    outline: none;

    &.error {
        border: 1px solid $pink;
    }

    &:focus {
        border: 1px solid $pink;
    }

}
textarea {
    background: $body_background;
    border: 1px solid $border;
    color: $primary_color;
    @include radius(4px);
    padding: 5px 10px;
    width: 100%;
    outline: none;
    height: 102px;
    overflow: auto;
}
select {
    line-height: normal;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background: $white url('../../assets/images/icons/ico_arrow_select.svg') no-repeat right center;
    background-size: 26px;
    position: relative;
    width: 100%;
    @include radius(50px);
    height: 36px;
    padding: 0 33px 0 15px;
    outline: none;
    border-color: $border;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 5px;
        height: 5px;
        background: $white;
    }

    &::-ms-expand {
        display: none;
    }
}
.form-search {
    position: relative;
    img {
        position: absolute;
        left: 15px;
        top: 12px;
    }
    input[type="text"] {
        padding-left: 35px !important;
    }
    &.icon-right {
        img {
            right: 15px;
            left: inherit;
        }
        input[type="text"] {
            padding-left: 10px !important;
            padding-right: 35px !important;
        }
    }
}
input[type="checkbox"]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: url(../../assets/images/icons/ico_uncheck.svg) no-repeat 0 0;
    -webkit-background-size: 16px;
    background-size: 16px;
    position: relative;
    cursor: pointer;
    outline: none;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    margin-right: 5px;
    &:checked {
        background: url(../../assets/images/icons/ico_checked.svg) no-repeat 0 0;
        -webkit-background-size: 16px;
        background-size: 16px;
    }
    &:focus {
        outline: none;
    }
}
.col-list {
    margin-bottom: -30px;
    .col-item {
        margin-bottom: 30px;
    }
}
.switch-list {
    line-height: 36px;
    label {
        margin: 0;
        color: $primary_color!important;
        font-size: $f14;
        cursor: pointer;
        &+ label {
            margin-top: 10px!important;
        }
    }
    input[type="checkbox"]{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 36px;
        height: 18px;
        background: $gray_mid;
        @include radius(11px);
        position: relative;
        cursor: pointer;
        outline: none;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        display: inline-block;
        vertical-align: middle;
        &:after{
            position: absolute;
            content: "";
            width: 14px;
            height: 14px;
            @include radius(50%);
            background: $white;
            -webkit-box-shadow: 0 0 .25em rgba(0,0,0,.3);
            box-shadow: 0 0 .25em rgba(0,0,0,.3);
            left: 2px;
            top: 2px;
            -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
        }
        &:checked {
            background: $pink;
            -webkit-box-shadow: inset 0 0 5px 0 $dark_pink;
            box-shadow: inset 0 0 5px 0 $dark_pink;
            &:after{
                left: 20px;
            }
        }
        &+ span {
            display: inline-block;
            vertical-align: middle;
            padding-left: 10px;
        }
    }
    span {
        &+ input[type="checkbox"] {
            margin-left: 10px;
        }
        em {
            color: $gray;
        }
    }
    &.gray {
        span {
            color: $gray;
        }
    }
}
.checkbox-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        vertical-align: middle;
        padding: 0 30px 0 0;
        &:last-child {
            padding-right: 0;
        }
    }
}
.head-bar {
    float: right;
    position: relative;
    min-height: 36px;
    padding-right: 60px;
    .top-filter {
        position: absolute;
        right: 0;
        top: 0;
        min-height: 36px;
        min-width: 37px;
        z-index: 100;
    }
    .search-button {
        border: 1px solid $border;
        width: 37px!important;
        height: 36px;
        cursor: pointer;
        @include radius(50%);
        margin: 0;
        position: absolute;
        background: $white;
        right: 0;
        top: 0;
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
        &.show-search {
            display: none;
            &+ .search-enpoint {
                display: block !important;
            }
        }
    }
    .search-enpoint {
        display: none;

        // width: 37px!important;
        height: 36px;
        // cursor: pointer;
        // input[type='text'] {
        //     width: 37px;
        //     height: 36px;
        //     padding: 0;
        // }
        // img {
        //     left: 50%!important;
        //     -webkit-transform: translate(-50%, 0);
        //     -ms-transform: translate(-50%, 0);
        //     transform: translate(-50%, 0);
        // }
        &.show-search {
            // width: 400px!important;
            // input[type='text'] {
            //     width: 100%;
            //     padding-left: 0;
            // }
            // img {
            //     left: 15px!important;
            //     -webkit-transform: translate(0)!important;
            //     -ms-transform: translate(0)!important;
            //     transform: translate(0)!important;
            // }
        }
    }
}
.filter-bar {
    float: none;
    display: block;
    clear: both;
    padding-top: 8px;
    .filter-list {
        .filter-item {
            display: inline-block;
            vertical-align: middle;
            .txt-item {
                color: $gray;
                font-size: $f14;
                margin: 0;
                display: inline-block;
                vertical-align: middle;
            }
            .filter-choice {
                display: inline-block;
                vertical-align: middle;
                padding-left: 10px;
            }
            &+ .filter-item {
                padding-left: 40px;
            }
        }
    }
}
.mt15 {
    margin-top: 15px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mb15 {
    margin-bottom: 15px !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb5 {
    margin-bottom: 5px !important;
}
.pt10 {
    padding-top: 10px !important;
}
.pt20 {
    padding-top: 20px !important;
}

#Header {
    background: $white;
    min-height: 60px;
    padding: 0 20px 0 70px;
    position: fixed;
    left: 0;
    top: 0;
    box-shadow: 0 0 16px 0 rgba(58, 75, 207, 0.14);
    width: 100%;
    z-index: 300;
    .select-dashboard {
        display: inline-block;
        left: 100px;
        min-width: 235px;
    }
    .header-left {
        display: none;
    }
    .header-block {

        .add-new {
            margin: 0;
            display: inline-block;
            vertical-align: middle;
        }

        .block-user {
            display: inline-block;
            vertical-align: middle;

            .btn-user {
                height: auto;
                padding: 10px 0;
                margin-left: 40px;

                img {
                    width: 40px;
                }

                span {
                    padding-left: 10px;
                    color: $dark_gray;
                }

                &:hover {
                    opacity: 0.8;
                    filter: alpha(opacity=80);
                }
            }

            .dropdown-toggle {
                span {
                    padding-right: 5px;
                }

                .arrow {
                    padding-top: 2px;
                    width: 12px;
                }

                &:after {
                    border: none;
                }
            }

            .dropdown-menu {
                left: inherit !important;
                right: 0;
            }
        }
    }
}

.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 70px;
    background: $white;
    -webkit-box-shadow: 0 0 16px 0 rgba(58, 75, 207, 0.14);
    box-shadow: 0 0 16px 0 rgba(58, 75, 207, 0.14);
    padding: 0 7px;
    z-index: 301;

    #Logo {
        display: block;
        padding: 15px 0 0 0;
        margin: 0;
        text-align: center !important;
        width: 100%;

        a {
            &:hover {
                opacity: 0.8;
                filter: alpha(opacity=80);
            }
        }
    }

    .side-nav {
        list-style: none;
        margin: 0;
        padding: 35px 0 0 0;

        li {
            a {
                padding: 0;
                text-align: center;
                font-size: $f14 - 2;
                color: $gray;
                display: block;
                text-decoration: none;

                img {
                    height: 20px;

                    &.img-active {
                        display: none;
                        visibility: hidden;
                    }
                }

                span {
                    padding-top: 2px;
                    display: block;
                }

                &:hover {
                    color: $pink;

                    img {
                        visibility: hidden;
                        display: none;

                        &.img-active {
                            display: inline-block;
                            visibility: visible;
                        }
                    }
                }

                &.active {
                    color: $pink;

                    img {
                        visibility: hidden;
                        display: none;

                        &.img-active {
                            display: inline-block;
                            visibility: visible;
                        }
                    }
                }

                .side-bar-icon {
                    font-size: 22px
                }
            }

            &+li {
                padding: 30px 0 0 0;
            }
        }
    }

    .select-dashboard {
        display: none;
    }
}

#Content {
    padding: 100px 50px 0 120px;
}

/* Css for nav tab */
.head-tab {
    .current-tab {
        display: none;
        visibility: hidden;
    }
}
.nav-tabs {
    border-bottom: 2px solid $border;
    .nav-item {
        cursor: pointer;
        a {
            background: none;
            padding: 12px 10px;
            border: none;
            position: relative;
            font-size: $f14 + 2;
            color: $gray;

            &:hover {
                background: none;
                color: $pink;
            }

            &.active {
                background: none;
                color: $pink;
                font-weight: bold;

                &:before {
                    background: $pink;
                    content: '';
                    height: 4px;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                }
            }
        }

        &+.nav-item {
            padding-left: 20px;
        }
    }
}
.tab-content {
    .tab-pane {
        padding: 25px 0;
    }
}
.tab-create {
    padding: 5px 0 0 0;
    .label-tab {
        float: left;
        width: auto;
        h4 {
            margin: 0 !important;
            padding: 0 !important;
            span {
                display: block;
                font-size: $f14;
                font-weight: normal;
                color: $gray;
                padding: 2px 0 0 0;
            }
        }
        &+ .nav-tabs {
            float: right;
            padding: 3px 0 0 0;
        }
    }
    .nav-tabs {
        border: none !important;
        .nav-item {
            width: 110px;
            margin-right: 13px;
            padding: 0;
            a {
                background: $body_background;
                display: block;
                padding: 0;
                color: #003C3A;
                height: 32px;
                line-height: 30px;
                border: 1px solid $border;
                text-align: center;
                @include radius(40px);
                font-weight: normal !important;
                font-size: $f14;
                &.active {
                    background: $pink;
                    color: $white;
                    border-color: $pink;
                }
                &:before {
                    display: none;
                }
            }
            &+ .nav-item {
                padding: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .tab-content {
        .tab-pane {
            padding: 20px 0 0;
            .day-list {
                padding: 20px 0 0 0;
                ul {
                    list-style: none;
                    margin: 0 -5px 0 0;
                    padding: 0;
                    li {
                        float: left;
                        margin-right: 16px;
                        width: 60px;
                        label {
                            margin: 0;
                            position: relative;
                            input[type="checkbox"]{
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                position: absolute;
                                visibility: hidden;
                                &+ span {
                                    display: block;
                                    font-size: $f14;
                                    color: $gray;
                                    border: 1px solid $border;
                                    @include radius(100px);
                                    text-align: center;
                                    cursor: pointer;
                                    width: 60px;
                                    height: 60px;
                                    line-height: 58px;
                                }
                                &:checked {
                                    &+ span {
                                        background: rgba(255, 105, 132, .1);
                                        border: 1px solid $pink;
                                        color: $pink;
                                    }
                                }

                            }
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

/* Css for data grid */
.data-grid {
    padding: 30px 0 0 0;
    .bot-grid {
        padding: 30px 0 0 0;
        position: relative;
    }
}

.filter-column {
    position: absolute;
    right: 0;
    top: 35px;
    z-index: 90;
    .view-filter {
        margin: 0;
        text-align: right;
        span {
            border: 1px solid $border;
            display: inline-block;
            background: $white url('../../assets/images/icons/ico_view.svg') no-repeat center center;
            @include radius(4px);
            width: 26px;
            height: 26px;
            text-align: center;
            cursor: pointer;
            &:hover {
                background: $white url('../../assets/images/icons/ico_view_o.svg') no-repeat center center;
            }
        }
    }
    ul {
        background: $white;
        list-style: none;
        margin: 0;
        padding: 0;
        border-color: $border;
        -webkit-box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
        box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
        width: 100%;
        padding: 10px;
        @include radius(4px);
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 100;
        margin-top: 5px;
        min-width: 200px;
        li {
            color: $black_blue;
            font-size: $f14;
            label {
                color: $black_blue;
                cursor: pointer;
                margin: 0;
            }
            &+ li {
                padding-top: 10px;
            }
        }
        &:before {
            position: absolute;
            top: -8px;
            right: 5px;
            display: inline-block;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            border-bottom-color: transparent;
            content: '';
        }

        &:after {
            position: absolute;
            top: -10px;
            right: 6px;
            display: inline-block;
            border-right: 7px solid transparent;
            border-bottom: 13px solid $white;
            border-left: 7px solid transparent;
            content: '';
        }
    }
}

/* Css for table */
.ReactTable {
    background: none !important;
    border: none !important;

    .rt-tr {
        align-items: center;
    }

    .rt-thead {
        &.-header {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            background: none !important;
            text-transform: uppercase;
        }

        .rt-th {
            border: none !important;
            padding: 10px !important;
            text-align: left !important;

            .rt-resizable-header-content {
                color: $primary_color;
                -webkit-box-shadow: none !important;
                box-shadow: none !important;
                background: url('../../assets/images/icons/ico_sort.svg') no-repeat right 4px;
                display: inline-block;
                padding-right: 12px;
                background-size: 8px;
                font-weight: 500;
            }

            /* Sort */
            &.-sort-desc {
                -webkit-box-shadow: none !important;
                box-shadow: none !important;

                .rt-resizable-header-content {
                    -webkit-box-shadow: none !important;
                    box-shadow: none !important;
                    background: url('../../assets/images/icons/ico_sort_down.svg') no-repeat right 4px;
                    padding-right: 12px;
                    background-size: 8px;
                }
            }

            &.-sort-asc {
                -webkit-box-shadow: none !important;
                box-shadow: none !important;

                .rt-resizable-header-content {
                    -webkit-box-shadow: none !important;
                    box-shadow: none !important;
                    background: url('../../assets/images/icons/ico_sort_up.svg') no-repeat right 4px;
                    padding-right: 12px;
                    background-size: 8px;
                }
            }
        }
    }

    .rt-tbody {
        // overflow-x: hidden !important;
        overflow: unset !important;
        .rt-tr-group {
            background: $white;
            border: none !important;
            margin-bottom: 5px;
            @include radius(4px);
            padding: 0 !important;
            &:last-child {
                .rt-tr {
                    .dropdown {
                        .dropdown-menu {
                            top: inherit !important;
                            bottom: 100% !important;
                        }
                    }
                }
            }
        }

        .rt-th {
            padding: 7px 10px;
        }

        .rt-td {
            border: none !important;
            padding: 7px 10px;
            white-space: inherit;
            overflow: inherit;
            word-break: break-word;
            .has-img {
                img {
                    float: left;
                    max-width: 25px;
                    height: auto;
                    &+ span {
                        display: block;
                        margin: 0;
                        padding: 0 0 0 30px;
                    }
                }
            }
            .id-custom-table {
                font-size: $f12;
                color: $gray;
            }
        }
    }

    .-pagination {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        border: none !important;
    }

    .Table__pagination {
        padding: 20px 0;

        .react-table-pagination__page-size-options {
            color: $gray;

            .paging-select {
                display: inline-block;
                vertical-align: middle;

                select {
                    line-height: normal;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    -ms-appearance: none;
                    appearance: none;
                    background: $white url('../../assets/images/icons/ico_arrow_down.svg') no-repeat 48px center;
                    background-size: 10px;
                    position: relative;
                    width: 65px;
                    height: 36px;
                    border-color: $border;
                    color: $primary_color;
                    padding: 5px 10px;

                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 5px;
                        height: 5px;
                        background: #333;
                    }

                    &:active,
                    &:focus {
                        outline: none !important;
                    }
                }
            }

            .txt-paging {
                display: inline-block;
                vertical-align: middle;
                padding-left: 10px;
            }
        }

        .Table__numberButtonWrapper {
            border: 1px solid $border;
            @include radius(4px);
            overflow: hidden;

            .Table__prevPageWrapper {
                .Table__pageButton {
                    height: 36px;
                    line-height: 36px;
                    padding: 0 5px !important;
                    margin: 0;

                    &:active,
                    &:focus {
                        outline: none !important;
                        border-color: $border !important;
                    }
                }
            }

            .Table__nextPageWrapper {
                .Table__pageButton {
                    height: 36px;
                    line-height: 36px;
                    padding: 0 8px !important;
                    margin: 0;
                    border-left: 1px solid $border;

                    &:active,
                    &:focus {
                        outline: none !important;
                        border-color: $border !important;
                    }
                }
            }

            .Table__visiblePagesWrapper {
                .Table__pageButton {
                    border-left: 1px solid $border;
                    margin: 0;
                    width: 36px;
                    height: 36px;
                    text-align: center;
                    line-height: 36px;
                    padding: 0;

                    &:active,
                    &:focus {
                        outline: none !important;
                        border-color: $border !important;
                    }
                }
            }
        }
    }
}

// Button action
.action-btn {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;
    li {
        display: inline-block;
        vertical-align: middle;
        height: 24px;
        position: relative;
        &+ li {
            padding-left: 20px;
        }
        button {
            border: none !important;
            margin: 0;
            padding: 0;
            background: none !important;
            width: 24px;
            height: 24px;
            text-align: center;
            position: relative;
            @include radius(0);
            img {
                display: block;
                margin: 0 auto;
                &.img-active {
                    display: none;
                    visibility: hidden;
                }
            }
            &:hover {
                background: none !important;
                border: none !important;
                -webkit-box-shadow: none !important;
                box-shadow: none !important;
                img {
                    display: none;
                    visibility: hidden;
                    &.img-active {
                        display: block;
                        visibility: visible;
                    }
                }
            }
            &:active,
            &:focus {
                background: none !important;
                border: none !important;
                -webkit-box-shadow: none !important;
                box-shadow: none !important;
                img {
                    display: none;
                    visibility: hidden;
                    &.img-active {
                        display: block;
                        visibility: visible;
                    }
                }
            }
            &.sub-action-show {
                &+ .sub-action {
                    display: block!important;
                }
            }
        }
        ul {
            display: none;
            position: absolute;
            left: inherit !important;
            right: 10px !important;
            top: 100%;
            list-style: none;
            margin: 0;
            padding: 0 0;
            width: 130px;
            background: $white;
            -webkit-box-shadow: 0 0 20px 0 rgba(58,75,207,0.16);
            box-shadow: 0 0 20px 0 rgba(58,75,207,0.16);
            @include radius(4px);
            z-index: 100;
            min-width: inherit;
            li {
                display: block;
                width: 100%;
                height: auto;
                border: none;
                padding: 7px 10px;
                text-align: left;
                cursor: pointer;
                img {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 auto;
                    &.img-active {
                        display: none;
                        visibility: hidden;
                    }
                }
                span {
                    display: inline-block;
                    vertical-align: middle;
                    padding-left: 5px;
                }
                &:hover,
                &:active,
                &:focus {
                    background: none;
                    img {
                        display: none;
                        visibility: hidden;
                        &.img-active {
                            display: inline-block;
                            visibility: visible;
                        }
                    }
                    span {
                        color: $pink;
                    }
                }
                &+ li {
                    border-top: 1px solid $border;
                }
            }
        }
    }
}

.rpm-custom-percent-status {
    font-size: $f14 - 2;
    font-weight: normal;
    color: $green;
    margin-left: 5px;

    &.up {
        background: url('../../assets/images/icons/ico_up.svg') no-repeat left 4px;
        color: $green !important;
    }

    &.down {
        background: url('../../assets/images/icons/ico_down.svg') no-repeat left 4px;
        color: $light_red !important;
    }

    .text-percent {
        margin-left: 10px;
    }
}


/* Css for datepicker UI */
.react-datepicker-popper {
    .react-datepicker {
        border: 1px solid $border;
        font-family: 'Roboto', sans-serif;
        -webkit-box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
        box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);

        .react-datepicker__triangle {
            display: none;
        }

        .react-datepicker__header {
            background: $white;
            border-bottom: none;
            padding-top: 15px;
        }

        .react-datepicker__navigation {
            &.react-datepicker__navigation--next {
                background: url('../../assets/images/icons/ico_arrow_down.svg') no-repeat center center;
                background-size: 14px;
                border: none;
                -webkit-transform: rotate(-90deg);
                -moz-transform: rotate(-90deg);
                -ms-transform: rotate(-90deg);
                -o-transform: rotate(-90deg);
                width: 22px;
                height: 22px;
                top: 14px;
                right: 35px;
            }

            &.react-datepicker__navigation--previous {
                background: url('../../assets/images/icons/ico_arrow_down.svg') no-repeat center center;
                background-size: 14px;
                border: none;
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                width: 22px;
                height: 22px;
                top: 14px;
                left: 35px;
            }
        }

        .react-datepicker__month {
            margin-top: 0;
        }

        .react-datepicker__day-name {
            color: $gray_light;
            width: 30px;
            height: 25px;
            line-height: 25px;
            margin: 5px;
            text-transform: uppercase;
        }

        .react-datepicker__day {
            font-size: $f14;
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin: 5px;

            &:hover {
                color: $white;
                background: rgba(255, 105, 132, 0.5);
                @include radius(50%);
            }
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range {
            @include radius(50%);
            background: $pink;
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected {
            @include radius(50%);
            background: $pink;
        }

        .react-datepicker__day--in-range {
            color: $white;
            background: rgba(255, 105, 132, 0.7);
            @include radius(50%);
        }

        .react-datepicker__day--range-start {
            color: $white;
            @include radius(50%);
            background: $pink;
        }

        .react-datepicker__day--range-end {
            background: $pink;
        }

        .react-datepicker__day--today {
            color: $pink;
            position: relative;
            background: none;

            &:hover {
                background: none;
                color: $pink;
            }

            &:before {
                content: '';
                width: 5px;
                height: 5px;
                background: $pink;
                @include radius(50%);
                position: absolute;
                left: 50%;
                bottom: 0;
                -webkit-transform: translate(-50%, 0);
                -ms-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
            }
        }
    }
}
.box-date {
    position: relative;
    .react-datepicker-wrapper {
        display: block;
        .react-datepicker__input-container {
            display: block;
        }
    }
    img {
        position: absolute;
        right: 10px;
        top: 50%;
        width: 16px;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }
}

.react-datepicker-popper[data-placement^='top'] {
    margin-bottom: 5px !important;
}

.react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 5px !important;
}
.select-time {
    position: relative;
    .select-time-box {
        width: 110%;
        background: $white;
        box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
        padding: 10px;
        @include radius(4px);
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 10;
        .time-list {
            float: left;
            width: 50%;
            padding-right: 5px;
            &+ .time-list {
                float: right;
                width: 50%;
                padding-left: 5px;
            }
        }
        .react-datepicker__time-container {
            width: 102px !important;
            .react-datepicker__time {
                .react-datepicker__time-box {
                    width: 103px;
                }
            }
        }
        .react-datepicker-popper {
            .react-datepicker {
                .react-datepicker__header {
                    padding: 5px 5px 0 5px;
                }
            }
        }
        .react-datepicker__time-list-item {
            &:hover {
                background: rgba(255, 105, 132, .1)!important;
            }
            &.react-datepicker__time-list-item--selected {
                background: $pink !important;
            }
        }
        .set-time {
            margin: 0;
            padding: 10px 0 0 0;
            .btn {
                width: 100%;
            }
        }

        .react-select__control {
            .react-select__indicators {
                .react-select__dropdown-indicator {
                    width: 25px;
                }
            }
        }
    }
}

/* Css for multi select */
.react-select__control {
    border: 1px solid $border;
    @include radius(18px);
    // height: 36px;

    &.react-select__control--is-focused {
        border-color: $pink;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;

        &:hover {
            border-color: $pink;
        }
    }

    .react-select__value-container {
        // height: 36px;

        .react-select__multi-value {
            // background: none;
            // margin: 0;
            border-radius: 13px;
            font-size: 12px;
            color: #1B1C87;

            .react-select__multi-value__label {
                font-size: $f14;
                // color: $primary_color;
                color: #1B1C87;
                background: none;
                // padding-left: 8px;
                // padding-right: 2px;
                position: relative;

                &:before {
                    // content: ',';
                    // position: absolute;
                    // left: 0;
                    // bottom: 3px;
                }
            }

            .react-select__multi-value__remove {
                // display: none;
            }

            &:first-child {
                .react-select__multi-value__label {
                    padding-left: 5px;

                    &:before {
                        content: '';
                    }
                }
            }
        }

        .css-1g6gooi {
            height: 27px !important;
        }

        .react-select__input {
            height: 27px !important;

            input {
                height: 25px;
            }
        }
    }

    .react-select__indicators {
        .react-select__dropdown-indicator {
            background: url('../../assets/images/icons/ico_arrow_down.svg') no-repeat center center;
            background-size: 13px;
            width: 35px;
            height: 10px;
            display: block;

            svg {
                display: none !important;
            }
        }
    }
}
.react-select__menu {
    background: $white !important;
    margin: 2px 0 0 0 !important;
    -webkit-box-shadow: 0 10px 20px 3px rgba(58, 75, 207, 0.25) !important;
    box-shadow: 0 10px 20px 3px rgba(58, 75, 207, 0.25) !important;
    @include radius(4px);
    overflow: hidden;

    .react-select__menu-list {
        &.react-select__menu-list--is-multi {
            padding: 5px 10px;

            .react-select__option {
                position: relative;
                padding-left: 26px;
                padding-top: 5px;
                padding-bottom: 5px;

                &:before {
                    content: '';
                    background: url(../../assets/images/icons/ico_uncheck.svg) no-repeat 0 0;
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    left: 0;
                    top: 7px;
                }

                &.react-select__option--is-focused {
                    background: none;
                }

                &.react-select__option--is-selected {
                    background: none;
                    color: $primary_color;

                    &:before {
                        content: '';
                        background: url(../../assets/images/icons/ico_checked.svg) no-repeat 0 0;
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        left: 0;
                        top: 7px;
                    }
                }

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                    filter: alpha(opacity=70);
                }
            }
        }
    }
}

.react-select-multi-line__control {
    @include radius(18px);
    border-color: $border;
    display: block!important;
    position: relative;
    .react-select-multi-line__value-container {
        padding: 5px 75px 5px 10px;
        display: block !important;
        &.react-select-multi-line__value-container--has-value {
            // padding: 8px 0 0 30px;
            flex: inherit!important;
            flex-wrap: inherit!important;
        }
        .react-select-multi-line__multi-value {
            display: inline-block;
            vertical-align: middle;
            color: $purple;
            background: rgba(27, 28, 135, .1);
            @include radius(18px);
            padding: 1px 17px 1px 1px;
            position: relative;
            .react-select-multi-line__multi-value__label {
                color: $purple;
                font-size: $f14 - 2;
            }
            .react-select-multi-line__multi-value__remove {
                position: absolute;
                right: 2px;
                top: 6px;
                padding: 0;
                &:hover {
                    background: none;
                    color: $pink;
                }
            }
        }
        .css-1g6gooi {
            display: inline-block;
            vertical-align: middle;
            height: 22px;
            overflow: hidden;
            .react-select-multi-line__input {
                input {
                    height: 20px;
                }
            }
        }
    }
    .react-select-multi-line__indicators {
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        .react-select-multi-line__dropdown-indicator {
            display: none;
        }
        .react-select-multi-line__indicator-separator {
            display: none;
        }
    }
    &.react-select-multi-line__control--is-focused {
        border-color: $pink;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;

        &:hover {
            border-color: $pink;
        }
    }
}
.react-select-multi-line__menu {
    background: $white !important;
    margin: 2px 0 0 0 !important;
    -webkit-box-shadow: 0 10px 20px 3px rgba(58, 75, 207, 0.25) !important;
    box-shadow: 0 10px 20px 3px rgba(58, 75, 207, 0.25) !important;
    @include radius(4px);
    overflow: hidden;
    z-index: 100;
    .react-select-multi-line__menu-list {
        &.react-select-multi-line__menu-list--is-multi {
            padding: 0!important;
        }
    }
}
/* Select  */
.react-select-container-no-border {
    // z-index: 100;
    // Main select
    .react-select-no-border__control--menu-is-open {
        border: none !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .react-select-no-border__control {
        border: none;
        background: none;
        display: inline-block;
        min-height: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        .react-select-no-border__value-container {
            padding: 0;
            overflow: inherit;
            .react-select-no-border__input {
                input {
                    height: 0;
                }
            }
        }
        .react-select-no-border__single-value {
            color: $primary_color;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            position: relative;
            padding-right: 12px;
            margin: 0 2px 0 0;
            cursor: pointer;
            max-width: inherit;
            &:after {
                content: "";
                background: url('../../assets/images/icons/ico_arrow_down_bg.svg') no-repeat center center;
                width: 7px;
                height: 5px;
                position: absolute;
                right: 0;
                top: 8px;
            }
        }
        .react-select-no-border__indicators {
            display: none;
        }
        .css-1g6gooi {
            height: 15px;
        }
    }
    .react-select-no-border__menu {
        min-width: 150px;
        box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.2);
        z-index: 100;
    }
    .react-select-no-border__menu-list {
        border: 1px solid $border;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
        @include radius(4px);
        .react-select-no-border__option {
            border-bottom: 1px solid $border;
            &:hover {
                background: rgba(255, 105, 132, .1);
            }
            &:last-child {
                border: none;
            }
        }
        .react-select-no-border__option--is-focused {
            background: none;
            cursor: pointer;
        }
        .react-select-no-border__option--is-selected {
            background: $white;
            color: $pink;
            &:hover {
                background: $white;
                color: $pink;
            }
        }
    }
}
/* Select mask default */
.react-select-container-default {
    // z-index: 100;
    cursor: pointer;
    clear: both;
    // Main select
    .react-select-default__control--menu-is-open {
        border: none !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .react-select-default__control {
        border: none;
        background: none;
        display: block;
        min-height: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: pointer;
        .react-select-default__value-container {
            border: 1px solid $border;
            @include radius(18px);
            padding: 7px 15px 6px;
            overflow: inherit;
            min-width: 50px;
            &:after {
                content: "";
                background: url('../../assets/images/icons/ico_arrow_select.svg') no-repeat right center;
                background-size: 26px;
                width: 30px;
                height: 16px;
                position: absolute;
                right: 0;
                top: 8px;
            }
            .react-select-default__input {
                input {
                    height: 0;
                }
            }
        }
        .react-select-default__single-value {
            color: $primary_color;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            position: relative;
            padding-right: 12px;
            margin: 0 2px 0 0;
            cursor: pointer;
        }
        .react-select-default__indicators {
            display: none;
        }
        .css-1g6gooi {
            height: 15px;
        }
    }
    .react-select-default__menu {
        min-width: 150px;
        box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.2);
        z-index: 100;
        margin-top: 2px;
    }
    .react-select-default__menu-list {
        border: 1px solid $border;
        padding-top: 0;
        padding-bottom: 0;
        // overflow: hidden;
        overflow:auto;
        @include radius(4px);
        .react-select-default__option {
            border-bottom: 1px solid $border;
            &:hover {
                background: rgba(255, 105, 132, .1);
            }
            &:last-child {
                border: none;
            }
        }
        .react-select-default__option--is-focused {
            background: none;
            cursor: pointer;
        }
        .react-select-default__option--is-selected {
            background: $white;
            color: $pink;
            &:hover {
                background: $white;
                color: $pink;
            }
        }
    }
}

/* Css for top Filter */
.top-btn {
    margin: 0 0 0 0;

    &>ul {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: right;

        &>li {
            display: inline-block;
            vertical-align: middle;

            .btn {
                * {
                    -webkit-transition: all 0 ease;
                    -moz-transition: all 0 ease;
                    -o-transition: all 0 ease;
                    transition: all 0 ease;
                }

                img {
                    &.img-active {
                        display: none;
                        visibility: hidden;
                    }
                }

                &:hover {
                    color: $pink;

                    img {
                        display: none;
                        visibility: hidden;

                        &.img-active {
                            display: inline-block;
                            visibility: visible;
                        }
                    }
                }

                &:after {
                    display: none;
                }
            }

            &.dropdown {
                &.show {
                    .btn {
                        color: $pink;
                        border-color: $pink;

                        img {
                            display: none;
                            visibility: hidden;

                            &.img-active {
                                display: inline-block;
                                visibility: visible;
                            }
                        }
                    }
                }

                .custom-filter {
                    .box-custom {
                        display: none;
                        padding: 5px 10px 7px;

                        .box-item {
                            margin: 0;
                            width: auto;

                            .label {
                                margin: 0;
                                color: $gray;
                            }

                            .box-date {
                                position: relative;

                                img {
                                    position: absolute;
                                    right: 10px;
                                    top: 8px;
                                    width: 16px;
                                }
                            }

                            &+.box-item {
                                margin-top: 5px;
                            }
                        }

                        .apply {
                            margin: 10px 0 0 0;
                            text-align: right;
                        }
                    }

                    &.show {
                        .box-custom {
                            display: block;
                        }
                    }

                    .show-box {
                        color: $pink;
                        padding-bottom: 0;

                        &+ {
                            .box-custom {
                                display: block;
                            }
                        }
                    }
                }
            }

            .search-enpoint {
                width: 400px;
                position: relative;

                img {
                    position: absolute;
                    left: 15px;
                    top: 12px;
                }

                input[type='text'] {
                    padding-left: 35px !important;
                }

                p {
                    margin: 0;
                }
            }

            &+li {
                padding-left: 10px;
            }
        }
    }
}

.block-filter {

    .filter-head {
        position: relative;
        padding-right: 20px;

        .ttl-block {
            margin: 0;
            font-size: $f14 + 4;
            color: $primary_color;
        }

        .btn-close {
            margin: 0;
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;

            span {
                background: url('../../assets/images/icons/ico_close.svg') no-repeat center center;
                display: block;
                width: 20px;
                height: 20px;

                &:hover {
                    background: url('../../assets/images/icons/ico_close_o.svg') no-repeat center center;
                }
            }
        }
    }

    .filter-content {
        padding: 20px 20px 0 0;

        .form-group {
            margin: 0;

            label {
                display: block;
                margin: 0;
                color: $gray;
            }

            &+.form-group {
                padding: 15px 0 0 0;
            }
        }

        .btn-list {
            list-style: none;
            margin: 0;
            padding: 0;
            position: absolute;
            left: 0;
            bottom: 82px;
            width: 100%;
            padding: 0 15px;

            li {
                float: left;

                .btn {
                    min-width: 150px!important;
                }

                &+li {
                    float: right;
                }
            }
        }
    }
}

.panel {
    background: $white;
    height: 100vh;
    z-index: 100;
    -webkit-box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.14);
    box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.14);
    padding: 15px;
    text-align: left;

    // &.abc {
    //     left: 72px !important;
    //     right: inherit !important;
    // }

    &.panel-create {
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
        -webkit-transform: translate(610px, 0);
        -ms-transform: translate(610px, 0);
        transform: translate(610px, 0);
        position: fixed;
        right: 0;
        top: 0;
        padding-top: 82px;
        width: 610px;
    }

    .panel-title {
        position: relative;
        padding-right: 20px;

        .ttl-panel {
            margin: 0;
            font-size: $f14 + 4;
            color: $primary_color;
        }

        .btn-close {
            position: relative;
            cursor: pointer;

            span {
                background: url('../../assets/images/icons/ico_close.svg') no-repeat center center;
                display: block;
                width: 20px;
                height: 20px;

                -webkit-transition: color 0.15s ease, -webkit-transform 0.3s ease;
                transition: color 0.15s ease, -webkit-transform 0.3s ease;
                -o-transition: color 0.15s ease, transform 0.3s ease;
                transition: color 0.15s ease, transform 0.3s ease;
                transition: color 0.15s ease, transform 0.3s ease, -webkit-transform 0.3s ease;

                &:hover {
                    background: url('../../assets/images/icons/ico_close_o.svg') no-repeat center center;
                    transform: rotate(90deg);
                }
            }
        }
    }

    .panel-content {
        padding: 0px 0 0 0;
        height: calc(100% - 80px);
        .content-inner {
            height: 100%;
            padding-bottom: 145px;
            position: relative;

            .panel-scrollbars {
                height: 100%;
            }

            .panel-form {
                display: inline;
            }

            &.loading {
                pointer-events: none;
            }
        }
        .form-group {
            margin: 0;

            &.add-new-form-group {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
            }

            &.no-label {
                justify-content: flex-end !important;
            }

            label {
                display: inline-block;
                margin: 0;
                color: $gray;
            }

            select {
                line-height: normal;
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                appearance: none;
                background: $white url('../../assets/images/icons/ico_arrow_select.svg') no-repeat right center;
                background-size: 26px;
                position: relative;
                width: 100%;
                @include radius(50px);
                height: 36px;
                padding: 0 10px;
                outline: none;
                border-color: $border;

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 5px;
                    height: 5px;
                    background: $white;
                }

                &::-ms-expand {
                    display: none;
                }
            }

            p {
                margin: 0;
            }

            .ttl-item {
                margin: 0 0 5px 0!important;
            }

            &+.form-group {
                padding: 15px 0 0 0;
            }
        }

        .step-list {
            margin: 0 0 20px 0;
            text-align: center;
            ul {
                display: inline-block;
                list-style: none;
                margin: 0;
                padding: 0;
                text-align: center;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    left: 13px;
                    top: 0;
                    background: $body_background;
                    height: 22px;
                    width: 95%;
                    @include radius(50px);
                }

                li {
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;

                    span {
                        display: block;

                        &.dot {
                            display: block;
                            width: 22px;
                            height: 22px;
                            @include radius(50px);
                            position: relative;
                            margin: 0 auto;
                            cursor: pointer;

                            &:after {
                                content: "";
                                background-image: linear-gradient(179deg, #B8B8B8 1%, #7F7F7F 100%);
                                width: 10px;
                                height: 10px;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                -webkit-transform: translate(-50%, -50%);
                                -ms-transform: translate(-50%, -50%);
                                transform: translate(-50%, -50%);
                                @include radius(50px);
                            }
                        }

                        &.passed {
                            &:before {
                                display: none;
                            }
                            &:after {
                                content: "";
                                background: url('../../assets/images/icons/ico_circle_checked.svg') no-repeat 0 0!important;
                                background-image: none;
                                width: 16px;
                                height: 16px;
                            }
                            &+ .text {
                                color: $purple;
                            }
                        }

                        &.curent {
                            &:before {
                                opacity: 0;
                                filter: alpha(opacity=0);
                            }

                            &:after {
                                background-image: linear-gradient(179deg, #4647B6 0%, #1B1C87 100%);
                            }
                            &+ .text {
                                color: $purple;
                            }
                        }
                    }

                    &+li {
                        padding-left: 30px;
                    }
                }
            }
        }

        .step-content {
            margin: 0;
            padding-bottom: 20px;
            padding-right: 15px;
            padding-left: 15px;
            height: 100%;
            // overflow: hidden;
            // overflow-y: auto;
            .box-date {
                position: relative;
                .img {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    -webkit-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                }
            }
            .review-block {
                .img-box {
                    position: relative;
                    @include radius(8px);
                    overflow: hidden;
                    -webkit-transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    transition: all 0.5s ease;
                    .img {
                        margin: 0;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        z-index: 10;
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                        visibility: hidden;
                        opacity: 0;
                        filter: alpha(opacity=0);
                        li {
                            display: inline-block;
                            vertical-align: middle;
                            button {
                                border: none;
                                background: none;
                                color: $white;
                                font-weight: normal;
                                font-size: $f14 + 2;
                                &:hover {

                                    color: $pink;
                                }
                            }
                            &+ li {
                                padding-left: 20px;
                            }
                        }
                    }
                    &:after {
                        content: "";
                        background: rgba(0, 1, 46, .67);
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        visibility: hidden;
                        opacity: 0;
                        filter: alpha(opacity=0);
                    }
                    &:hover {
                        ul {
                            visibility: visible;
                            opacity: 1;
                            filter: alpha(opacity=1);
                        }
                        &:after {
                            visibility: visible;
                            opacity: 1;
                            filter: alpha(opacity=1);
                        }
                    }
                }
                .txt {
                    color: #33475B;
                    font-size: $f14 + 2;
                    line-height: 1.3;
                    margin: 10px 0 0 0;
                }
            }
        }

        .group {
            padding: 15px 0 0 0;
            &:first-child {
                padding-top: 0;
            }

            .header-width-add {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .add-new {
                    p {
                        display: inline-block;
                        cursor: pointer;
                        color: $pink;
                        margin: 0 0 0 30px;
                        span {
                            display: inline-block;
                            padding-left: 3px;
                        }
                        &:hover {
                            opacity: .8;
                            filter: alpha(opacity=80);
                        }
                    }
                }
            }

            h4 {
                font-size: $f14 + 2;
                color: $primary_color;
                margin: 0;
                padding: 0 0 10px 0;

            }
            .group-item {
                .btn {
                    font-size: $f14;
                }
                &+ .group-item {
                    margin-top: 15px;
                }
            }
            .list-item {
                background: $white;
                -webkit-box-shadow: 0 0 10px 0 rgba(58,75,207,0.08);
                box-shadow: 0 0 10px 0 rgba(58,75,207,0.08);
                padding: 10px 20px;
                &+ .list-item {
                    margin-top: 10px;
                }
                .item-inner {
                    position: relative;
                    .item-left {
                        padding: 0 70px 0 0;
                        p {
                            margin: 0;
                            span {
                                display: block;
                                color: $gray;
                            }
                        }
                    }
                    .item-action {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        -webkit-transform: translate(0, -50%);
                        -ms-transform: translate(0, -50%);
                        transform: translate(0, -50%);
                        ul.item-action-ul {
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            z-index: inherit;
                            & > li {
                                display: inline-block;
                                vertical-align: middle;
                                button {
                                    border: none;
                                    padding: 0;
                                    background: none;
                                    outline: none;
                                    width: 24px;
                                    text-align: center;
                                    color: $gray;
                                    font-size: $f14 + 2;
                                    &:hover {
                                        color: $pink;
                                    }
                                }
                                &+ li {
                                    padding-left: 15px;
                                }
                            }
                        }

                        .item-action-dropdown {
                            .dropdown-toggle {
                                &:after {
                                    display: none;
                                }
                            }
                            .dropdown-menu {
                                min-width: 80px;
                                & > li {
                                    text-align: right;
                                    padding-left: 5px;
                                    padding-right: 5px;
                                    & > .menu-button {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &+ .group {
                padding: 20px 0 0 0;
            }
        }
        .row-list {
            .row-item {
                .switch-list {
                    float: left;
                    &+ .add-new {
                        float: right;
                        padding-top: 2px;
                    }
                }
                .item-block {
                    position: relative;
                    padding-right: 135px;
                    .switch-list {
                        position: absolute;
                        right: 0;
                        top: 7px;
                        margin-top: 0 !important;
                    }
                }
                &+ .row-item {
                    margin-top: 10px;
                }
            }
        }
        .row-list {
            .row-item {
                .add-new {
                    p {
                        display: inline-block;
                        cursor: pointer;
                        color: $pink;
                        margin: 0;
                        span {
                            display: inline-block;
                            padding-left: 3px;
                        }
                        &:hover {
                            opacity: .8;
                            filter: alpha(opacity=80);
                        }
                    }
                }
            }
        }
        .action-right {
            .row-list {
                .row-item {
                    position: relative;
                    padding-right: 40px;
                    .action-icon {
                        font-size: $f14 + 8;
                        position: absolute;
                        right: 0;
                        bottom: 3px;
                        cursor: pointer;
                        &.delete-item {
                            color: $gray;
                            font-size: $f14 + 4;
                            bottom: 5px;
                            right: 4px;
                            &:hover {
                                color: #f00;
                            }
                        }
                    }
                    .add-new {
                        text-align: right;
                        p {
                            display: inline-block;
                            cursor: pointer;
                            color: $pink;
                            margin: 0;
                            span {
                                display: inline-block;
                                padding-left: 3px;
                            }
                            &:hover {
                                opacity: .8;
                                filter: alpha(opacity=80);
                            }
                        }
                    }
                }
            }
        }

        .btn-list {
            list-style: none;
            margin: 0;
            padding: 0;
            position: absolute;
            left: 0;
            bottom: 15px;
            width: 100%;
            padding: 0 15px;
            text-align: center;

            li {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                .btn {
                    min-width: 150px !important;
                    &.dropdown-toggle {
                        position: relative;
                        span {
                            display: block;
                            padding: 0 20px 0 0;
                        }
                        img {
                            width: 14px;
                            position: absolute;
                            right: 10px;
                            top: 14px;
                            &.img-active {
                                display: none;
                                visibility: hidden;
                            }
                        }
                        &:hover {
                            img {
                                display: none;
                                visibility: hidden;
                                &.img-active {
                                    display: block;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
                .dropdown-menu {
                    border-color: $border;
                    -webkit-box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
                    box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
                    -webkit-transform: translate(0, 0);
                    -ms-transform: translate(0, 0);
                    transform: translate(0, 0);
                    width: 100%;
                    padding: 0;
                    top: -2px !important;
                    li {
                        display: block;
                        width: 100%;
                        a {
                            color: $black_blue;
                            padding: 7px 10px;
                            background: none;
                            display: block;
                            &:hover {
                                color: $pink;
                            }
                        }
                        &+li {
                            padding-left: 0;
                            border-top: 1px solid $border;
                        }
                    }
                }
                &.show {
                    .btn-light-violet {
                        background: $dark_violet !important;
                        color: $white !important;
                        img {
                            display: none;
                            visibility: hidden;
                            &.img-active {
                                display: block;
                                visibility: visible;
                            }
                        }
                    }
                }

                &+li {
                    padding-left: 15px;
                }
            }
        }
    }

    &.panel-show {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

.slider-range {
    .range-bar {
        .bar {
            display: block;
            width: 100%;
            background: #EEF3F6;
            @include radius(8px);
            height: 6px;
            position: relative;
            .point {
                background: #1B1B8B;
                @include radius(50px);
                border: 1px solid $white;
                display: block;
                width: 12px;
                height: 12px;
                -webkit-box-shadow: 0 0 6px 0 rgba(27,27,139,0.68);
                box-shadow: 0 0 6px 0 rgba(27,27,139,0.68);
                position: absolute;
                left: 0;
                top: -3px;
            }
        }
    }
}

@media screen and (min-width: 1439px) {
    .top-list-box {
        .box-item {
            flex: inherit;
        }
        &.five-col {
            .box-item {
                width: 20%;
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    #Header {
        padding: 0 15px 0 70px;
        .select-dashboard {
            left: 85px;
        }
    }
    #Content {
        padding: 85px 15px 0 85px;
    }
    .filter-bar {
        padding-top: 8px;
        .filter-list {
            .filter-item {
                &+ .filter-item {
                    padding-left: 25px;
                }
            }
        }
    }
    .data-grid {
        .bot-grid {
            padding: 20px 0 0 0;
        }
    }
    .filter-column {
        top: 25px;
    }
}

@media screen and (max-width: 1023px) {

}

@media screen and(max-width: 767px) {
    body {
        &.side-open {
            position: relative;
            &:before {
                content: "";
                background: rgba(0, 1, 46, .67);
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 301;
            }
        }
    }
    #Header {
        padding-left: 15px;
        z-index: 302;
        .header-left {
            display: inline-block;
            vertical-align: middle;
            padding: 10px 0;
            p {
                margin: 0;
                &.nav-toggle {
                    background: none;
                    margin: 0;
                    margin: 0;
                    left: 0;
                    top: 0;
                    position: relative;
                    opacity: 1;
                    filter: alpha(opacity=100);
                    visibility: visible;
                    z-index: 301;
                    padding: 0;
                    border: none;
                    width: 25px;
                    height: 19px;
                    display: inline-block;
                    vertical-align: middle;
                    cursor: pointer;
                    span {
                        display: block;
                        position: absolute;
                        height: 3px;
                        width: 50%;
                        background: $gray;
                        opacity: 1;
                        -webkit-transform: rotate(0deg);
                        -moz-transform: rotate(0deg);
                        -o-transform: rotate(0deg);
                        transform: rotate(0deg);
                        -webkit-transition: .25s ease-in-out;
                        -moz-transition: .25s ease-in-out;
                        -o-transition: .25s ease-in-out;
                        transition: .25s ease-in-out;
                        cursor: pointer;
                    }

                    span:nth-child(even) {
                        left: 50%;
                    }

                    span:nth-child(odd) {
                        left:0px;
                    }

                    span:nth-child(1), span:nth-child(2) {
                        top: 0px;
                    }

                    span:nth-child(3), span:nth-child(4) {
                        top: 8px;
                    }

                    span:nth-child(5), span:nth-child(6) {
                        top: 16px;
                    }

                    // &.open span:nth-child(1),
                    // &.open span:nth-child(6) {
                    //     -webkit-transform: rotate(45deg);
                    //     -moz-transform: rotate(45deg);
                    //     -o-transform: rotate(45deg);
                    //     transform: rotate(45deg);
                    // }

                    // &.open span:nth-child(2),
                    // &.open span:nth-child(5) {
                    //     -webkit-transform: rotate(-45deg);
                    //     -moz-transform: rotate(-45deg);
                    //     -o-transform: rotate(-45deg);
                    //     transform: rotate(-45deg);
                    // }

                    // &.open span:nth-child(1) {
                    //     left: 5px;
                    //     top: 5px;
                    // }

                    // &.open span:nth-child(2) {
                    //     left: 12px;
                    //     top: 5px;
                    // }

                    // &.open span:nth-child(3) {
                    //     left: -50%;
                    //     opacity: 0;
                    // }

                    // &.open span:nth-child(4) {
                    //     left: 100%;
                    //     opacity: 0;
                    // }

                    // &.open span:nth-child(5) {
                    //     left: 5px;
                    //     top: 12px;
                    // }

                    // &.open span:nth-child(6) {
                    //     left: 12px;
                    //     top: 12px;
                    // }
                }
                &#Logo {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 15px;
                }
            }
        }
        .header-block {
            .block-user {
                .btn-user {
                    margin-left: 15px;
                    padding: 9px 0;
                    span {
                        display: none;
                        &+ img {
                            display: none;
                        }
                    }
                }
            }
        }
        .select-dashboard {
            display: none;
        }
    }
    .side-open {
        #Header {
            .nav-toggle {
                span:nth-child(1),
                span:nth-child(6) {
                    -webkit-transform: rotate(45deg) !important;
                    -moz-transform: rotate(45deg) !important;
                    -o-transform: rotate(45deg) !important;
                    transform: rotate(45deg) !important;
                }

                span:nth-child(2),
                span:nth-child(5) {
                    -webkit-transform: rotate(-45deg) !important;
                    -moz-transform: rotate(-45deg) !important;
                    -o-transform: rotate(-45deg) !important;
                    transform: rotate(-45deg) !important;
                }

                span:nth-child(1) {
                    left: 5px !important;
                    top: 5px !important;
                }

                span:nth-child(2) {
                    left: 12px !important;
                    top: 5px !important;
                }

                span:nth-child(3) {
                    left: -50% !important;
                    opacity: 0 !important;
                }

                span:nth-child(4) {
                    left: 100% !important;
                    opacity: 0 !important;
                }

                span:nth-child(5) {
                    left: 5px !important;
                    top: 12px !important;
                }

                span:nth-child(6) {
                    left: 12px !important;
                    top: 12px !important;
                }
            }
        }
    }

    .sidebar {
        padding: 75px 5px 15px 5px;
        -webkit-transform: translateX(-250px);
        -ms-transform: translateX(-250px);
        transform: translateX(-250px);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        height: calc(100vh);
        width: 220px;
        #Logo {
            display: none;
        }
        .wrap-side {
            height: 100%;
            overflow-y: auto;
            padding: 0 10px;
        }
        .side-nav {
            padding: 0;
            li {
                a {
                    text-align: left;
                    img {
                        display: inline-block;
                        vertical-align: middle;

                    }
                    span {
                        display: inline-block;
                        vertical-align: middle;
                        padding: 0 0 0 10px;
                    }
                }
                &+ li {
                    padding-top: 20px;
                }
            }
        }
        .select-dashboard {
            display: block;
            padding: 20px 0 0 0;
        }
        // .side-mask {
        //     background: rgba(0, 1, 46, .67);
        //     position: fixed;
        //     left: 0;
        //     top: 0;
        //     width: 100%;
        //     height: 100%;
        // }
    }
    .side-open {
        .sidebar {
            -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
    }
    #Content {
        padding: 75px 10px 0 10px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

input.form-title-input {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    min-height: 25px;
    color: #333;
    letter-spacing: -0.1px;
    outline: none;
    padding: 5px 5px 5px 10px;
    border: 1px solid transparent;
    width: 100%;
    cursor: text;

    &:hover {
        border: 1px dashed #c4c4c4;
    }

    &:focus {
        border: 1px dashed #FF6984;
    }

}

// test version class
.versionClass {
    position: absolute;
    left: calc(50% - 150px);
    top: 35%;
    font-weight: bold;
    font-size: 30px;
}

.fade.show.popover {
    z-index: 10001;
}

@import 'upload_file.scss';
@import 'modal.scss';
@import 'tooltip.scss';
@import 'df_table.scss';
@import 'responsive.scss';
