@import "../../../assets/style/variable";

.table-tags-cell {
  display: block;
  position: relative;

  input.the-field {
    border: 1px solid hsl(0, 0%, 80%);
  }

  .the-field {
    width: 100%;
  }

  .tags-cell-box {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 15px;

    .tags-cell-actions {
      width: max-content;
      height: fit-content;
      align-self: center;
      font-size: 18px;

      .tags-cell-closing {
        i {
          color: $red;
        }

        cursor: pointer;
        margin-bottom: 8px;
      }

      .tags-cell-submit {
        i {
          color: $green;
        }

        cursor: pointer;
      }
    }

    .tags-cell-action {
      align-self: center;
      width: fit-content;
      cursor: pointer;

      img {
        display: inline-block;
        vertical-align: middle;
        height: 21px;

        &.img-active {
          display: none;
        }
      }

      &:hover {
        background: none;
        color: $pink;

        img {
          display: none;
          visibility: hidden;

          &.img-active {
            display: inline-block;
            visibility: visible;
          }
        }
      }
    }
  }
}
