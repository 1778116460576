.df-table-header {
  display: flex;
  justify-content: space-between;

  .title-container {

    .title {
      font-size: 22px;
      color: black;
      margin: 0;

      span {
        display: inline-block;
        color: $gray;
        font-size: 16px;
        padding-left: 5px;
      }
    }

  }

  .filter-bar {
    display: flex;

    .filter-item {
      display: flex;
      align-items: center;
      margin-left: 40px;
      position: relative;

      &:first-child {
        margin-left: 0;
      }

      .filter-title {
        color: gray;
        font-size: 14px;
        margin: 0 8px 0 0;      
      }

      &.filter-item-search {
        position: relative;
     }
    }

  }

}

.df-react-table {
  .table-action-btn {
    cursor: pointer;
  }
}
