@import "../../../assets/style/variable";

.wizard {
  &.panel.panel-create {
    position: unset;
    top: unset;
    right: unset;
    height: 100%;
    width: 100%;
    padding: 0;
  }

  display: grid;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: 1fr auto;
  column-gap: 20px;
  grid-template-areas: "steps content" "buttons buttons";

  &.panel {
    background-color: $body_background;

    .wizard-steps-box.panel-content {
      grid-area: steps;
      border-right: 1px solid $border;
      height: 100%;
      overflow: hidden;
      padding: 10px 0;
      min-width: 170px;

      .wizard-steps-container {
        height: 100%;
        overflow: hidden;

        .wizard-steps {
          height: 100%;
          overflow-y: auto;
        }
      }
    }
  }

  .wizard-content-box {
    grid-area: content;
    overflow: hidden;
    height: 100%;

    .wizard-content {
      display: grid;
      grid-template-rows: auto 1fr auto;
      padding: 40px 0 20px 0;
      height: 100%;
      overflow-y: auto;

      .panel-title {
        display: grid;
        grid-template-areas: "title close";
        grid-template-columns: 1fr 50px;
        padding: 0 30px 0 80px;

        .wizard-content-title {
          grid-area: title;

          &.form-group {
            margin-bottom: 5px;
          }

          input.form-title-input {
            background-color: $white;
          }
        }

        .btn-close {
          grid-area: close;
          width: fit-content;
          justify-self: end;
        }
      }

      .wizard-form-container.panel-content {
        overflow: hidden;
        height: 100%;
        padding: 15px 80px 0 80px;

        form {
          height: 100%;
          width: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          padding-bottom: 25px;
        }
      }

      .panel-footer {
        justify-self: center;
        padding: 20px 0;

        .btn {
          min-width: 150px!important;

          &.btn-pink-light {
            border-color: #FF6984;
          }

          &.second-btn {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .wizard-footer {
    grid-area: buttons;
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-template-areas: "back other";
    padding: 15px 0;
    background-color: $white;

    .wizard-footer-back-box {
      grid-area: back;
      justify-self: center;
      min-width: 190px;
      text-align: center;
    }

    .wizard-footer-other-box {
      grid-area: other;
      justify-self: end;
      padding: 0 130px 0 80px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "next finish";
      column-gap: 50px;

      .wizard-footer-next {
        grid-area: next;
      }

      .wizard-footer-finish {
        grid-area: finish;
      }
    }
  }
}
