@import "../../assets/style/variable";

.manual-revenue-update-form {
  background-color: #FFF;
  margin: 5px 0;
  word-break: break-word;
  white-space: pre-wrap;
  align-items: center;

  th {
    height: 60px;

    .form-group {
      .wizard-input-container {
        .the-field {
          height: 36px;

          .react-select__control {
            min-height: 36px;
            height: 36px;
          }
        }
      }
    }

    .form-group {
      margin-bottom: 0;
    }

    &.save-report-box {
      display: grid;
      align-items: center;

      .save-report-btn {
        color: $pink;
        cursor: pointer;
        width: fit-content;

        span {
          margin-left: 5px;
        }
      }
    }
  }
}
