@import "../../../assets/style/variable";

.wizard-input-container {
  display: block;
  position: relative;

  input.the-field {
    border: 1px solid hsl(0, 0%, 80%);

    &.title-forms {
      border: none;
    }
  }

  textarea.the-field {
    background-color: $white;
  }

  .the-field {
    width: 100%;

    &:focus {
      &.title-forms {
        border: 1px dashed $pink;
      }

      border: 1px solid $pink;
    }

    &.error {
      &.selectable {
        border: none;

        &.datepicker {
          border: 1px solid $pink;
        }
      }

      &.title-forms {
        border: 1px dashed $pink;
      }

      border: 1px solid $pink;

      .react-select__control {
        border: 1px solid $pink;
      }

      .react-datepicker__input-container {
        border: 1px solid $pink;
      }
    }
  }

  .field-error-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: $pink;
  }
}

.field-error-tooltip {
  padding: 12px;
  background-color: $pink;
  border-radius: 2px;
  color: white;
  z-index: 9998;
  right: 100%;
}


label.error {
  color: $pink !important;
}

.wizard-id-box {
  margin-bottom: 15px;
  font-weight: bold;

  span {
    color: $gray;
    font-weight: normal;
  }
}
