@import "../../assets/style/variable";

.reports-wizard {
  &.panel.panel-create {
    background-color: $body_background;
    position: unset;
    top: unset;
    right: unset;
    height: 100%;
    width: 100%;
    padding: 40px 0 0 0;

    .panel-title {
      padding: 0 70px 0 120px;

      h4 {
        text-align: center;
      }
    }
  }

  display: grid;
  grid-template-rows: auto 1fr auto;

  .panel-title {
    display: grid;
    grid-template-areas: "title close";
    grid-template-columns: 1fr auto;
  }

  .reports-wizard-container.panel-content {
    overflow: hidden;
    height: 100%;
    padding: 15px 100px 0 100px;

    .reports-wizard-form-content {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 20px;

      .filters-box {
        margin-top: 25px;

        &.last-filter-box {
          padding-bottom: 100px;

          .group-item .row-item .row {
            .filter-metrics-value {
              .wizard-input-container {
                margin-top: 2px;
              }
            }
          }
        }
      }
    }
  }

  .panel-footer {
    padding: 15px 150px 15px 100px;
    width: 100%;
    text-align: center;

    .hidden-CSV {
      display: none;
    }

    .reports-wizard-save {
      text-align: initial;
      display: grid;
      grid-template-columns: 1fr auto;
      margin-top: 20px;
      column-gap: 30px;

      .reports-wizard-save-input-box {
        display: block;
        position: relative;

        input {
          width: 100%;
          line-height: 1.5;
          font-size: 13px;
          font-family: 'Roboto', sans-serif;
          font-weight: normal;
          min-height: 25px;
          letter-spacing: normal;
          color: hsl(0, 0%, 20%);
          outline: none;
          padding: 5px 5px 5px 10px;
          border: 1px solid hsl(0, 0%, 80%);
          cursor: text;
          border-radius: 18px;

          &:focus {
            border: 1px solid $pink;
          }

          &.error {
            border: 1px solid $pink;
          }
        }

        .field-error-icon {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 20px;
          color: $pink;
        }
      }
    }

    .btn {
      &.second-btn {
        margin-left: 10px;
      }
    }
  }
}

.field-error-tooltip {
  padding: 12px;
  background-color: $pink;
  border-radius: 2px;
  color: white;
  z-index: 9998;
  right: 100%;
}
