 @media screen and (max-width: 1023px) {
     // ReactTable
    .ReactTable {
        .Table__pagination {
            display: block;
            padding: 15px 0;
            .react-table-pagination__page-size-options {
                margin-bottom: 10px;
                .paging-select {
                    float: left;
                    width: 65px;
                    select {
                        @include radius(4px);
                    }
                }
                .txt-paging {
                    display: block;
                    padding: 0 0 0 75px;
                    line-height: 1.3;
                }
            }
            .Table__numberButtonWrapper {
                display: block;
                border: none;
                text-align: right;
                margin-top: 10px;
                .Table__prevPageWrapper {
                    display: inline-block;
                    vertical-align: middle;
                    .Table__pageButton {
                        @include radius(5px 0 0 5px);
                        height: 28px;
                        line-height: 28px;
                    }
                }
                .Table__visiblePagesWrapper {
                    display: inline-block;
                    vertical-align: middle;
                    .Table__pageButton {
                        height: 28px;
                        width: 29px;
                        line-height: 28px;
                    }
                }
                .Table__nextPageWrapper {
                    display: inline-block;
                    vertical-align: middle;
                    .Table__pageButton {
                        @include radius(0 5px 5px 0);
                        height: 28px;
                        line-height: 28px;
                    }
                }
            }
        }
    }

    // Tab
    .head-tab {
        position: relative;
        padding-bottom: 5px;
        .current-tab {
            color: $pink;
            margin: 0;
            font-weight: 500;
            display: block;
            visibility: visible;
            span {
                color: $pink;
                display: inline-block;
                cursor: pointer;
                position: relative;
                font-size: $f14 + 4;
                i {
                    font-size: $f14 - 1;
                    margin: 1px 0 0 3px;
                }
            }
            &.tab-show {
                &+ .nav-tabs {
                    display: block !important;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    z-index: 100;
                }
            }
        }
        .nav-tabs {
            background: $white;
            margin: 1px 0 0 0;
            top: 100% !important;
            @include radius(4px);
            padding: 0;
            border: none;
            font-size: $f14;
            display: none;
            -webkit-box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
            box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
            transform: translate3d(0px, 0px, 0px) !important;
            min-width: 170px;
            li {
                display: block;
                width: 100%;
                &.nav-item {
                    a {
                        color: $black_blue;
                        padding: 7px 10px;
                        background: none;
                        &.active {
                            font-weight: 500;
                            &:before {
                                display: none;
                            }
                        }
                        &+a {
                            border-top: 1px solid $border;
                            border-color: rgba(225, 228, 234, 0.5);
                        }
                    }
                    &+ .nav-item {
                        padding: 0;
                        border-top: 1px solid $border;
                    }
                }
            }
            &:before {
                position: absolute;
                top: -8px;
                left: 9px;
                display: inline-block;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
                border-bottom-color: transparent;
                content: '';
            }

            &:after {
                position: absolute;
                top: -10px;
                left: 10px;
                display: inline-block;
                border-right: 7px solid transparent;
                border-bottom: 13px solid $white;
                border-left: 7px solid transparent;
                content: '';
            }
        }
    }

    // Data grid
    .data-grid {
        padding-top: 0!important;
        min-height: 400px;
    }

    // Filter bar
    .head-bar {
        float: none;
        padding-right: 45px;
    }

    .filter-bar {
        padding-top: 0;
        .filter-list {
            .filter-item {
                display: block;
                width: 100%;
                &+ .filter-item {
                    padding-left: 0;
                }
            }
        }
    }

    .tab-content {
        .tab-pane {
            padding: 0 0 25px 0;
        }
    }
 }
 @media screen and (max-width: 767px) {
    // Dashboard
    .top-list-box {
        margin-bottom: 10px;
        .box-item {
            margin-bottom: 10px !important;
        }
    }

    // Endpoint
    .head-grid {
        .endpoint-count {
            float: none;
            padding: 0;
            p {
                font-size: $f14 + 2;
            }
        }
    }

    .top-btn {
        &> ul {
            &> li {
                .search-enpoint {
                    width: 100%;
                }
            }
        }
    }

    // // ReactTable
    // .ReactTable {
    //     .Table__pagination {
    //         display: block;
    //         padding: 15px 0;
    //         .react-table-pagination__page-size-options {
    //             margin-bottom: 10px;
    //             .paging-select {
    //                 float: left;
    //                 width: 65px;
    //                 select {
    //                     @include radius(4px);
    //                 }
    //             }
    //             .txt-paging {
    //                 display: block;
    //                 padding: 0 0 0 75px;
    //                 line-height: 1.3;
    //             }
    //         }
    //         .Table__numberButtonWrapper {
    //             display: block;
    //             border: none;
    //             text-align: right;
    //             margin-top: 10px;
    //             .Table__prevPageWrapper {
    //                 display: inline-block;
    //                 vertical-align: middle;
    //                 .Table__pageButton {
    //                     @include radius(5px 0 0 5px);
    //                     height: 28px;
    //                     line-height: 28px;
    //                 }
    //             }
    //             .Table__visiblePagesWrapper {
    //                 display: inline-block;
    //                 vertical-align: middle;
    //                 .Table__pageButton {
    //                     height: 28px;
    //                     width: 29px;
    //                     line-height: 28px;
    //                 }
    //             }
    //             .Table__nextPageWrapper {
    //                 display: inline-block;
    //                 vertical-align: middle;
    //                 .Table__pageButton {
    //                     @include radius(0 5px 5px 0);
    //                     height: 28px;
    //                     line-height: 28px;
    //                 }
    //             }
    //         }
    //     }
    // }

    // // Tab
    // .head-tab {
    //     position: relative;
    //     padding-bottom: 5px;
    //     .current-tab {
    //         color: $pink;
    //         margin: 0;
    //         font-weight: 500;
    //         display: block;
    //         visibility: visible;
    //         span {
    //             color: $pink;
    //             display: inline-block;
    //             cursor: pointer;
    //             position: relative;
    //             font-size: $f14 + 4;
    //             i {
    //                 font-size: $f14 - 1;
    //                 margin: 1px 0 0 3px;
    //             }
    //         }
    //         &.tab-show {
    //             &+ .nav-tabs {
    //                 display: block !important;
    //                 position: absolute;
    //                 left: 0;
    //                 top: 100%;
    //                 z-index: 1000;
    //             }
    //         }
    //     }
    //     .nav-tabs {
    //         background: $white;
    //         margin: 1px 0 0 0;
    //         top: 100% !important;
    //         @include radius(4px);
    //         padding: 0;
    //         border: none;
    //         font-size: $f14;
    //         display: none;
    //         -webkit-box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
    //         box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
    //         transform: translate3d(0px, 0px, 0px) !important;
    //         min-width: 170px;
    //         li {
    //             display: block;
    //             width: 100%;
    //             &.nav-item {
    //                 a {
    //                     color: $black_blue;
    //                     padding: 7px 10px;
    //                     background: none;
    //                     &.active {
    //                         font-weight: 500;
    //                         &:before {
    //                             display: none;
    //                         }
    //                     }
    //                     &+a {
    //                         border-top: 1px solid $border;
    //                         border-color: rgba(225, 228, 234, 0.5);
    //                     }
    //                 }
    //                 &+ .nav-item {
    //                     padding: 0;
    //                     border-top: 1px solid $border;
    //                 }
    //             }
    //         }
    //         &:before {
    //             position: absolute;
    //             top: -8px;
    //             left: 9px;
    //             display: inline-block;
    //             border-right: 8px solid transparent;
    //             border-left: 8px solid transparent;
    //             border-bottom-color: transparent;
    //             content: '';
    //         }

    //         &:after {
    //             position: absolute;
    //             top: -10px;
    //             left: 10px;
    //             display: inline-block;
    //             border-right: 7px solid transparent;
    //             border-bottom: 13px solid $white;
    //             border-left: 7px solid transparent;
    //             content: '';
    //         }
    //     }
    // }

    // // Data grid
    // .data-grid {
    //     padding-top: 0!important;
    //     min-height: 400px;
    // }

    // // Filter bar
    // .head-bar {
    //     float: none;
    //     padding-right: 45px;
    // }
    // .filter-bar {
    //     padding-top: 0;
    //     .filter-list {
    //         .filter-item {
    //             display: block;
    //             width: 100%;
    //             &+ .filter-item {
    //                 padding-left: 0;
    //             }
    //         }
    //     }
    // }


 }

@media screen and (max-width: 639px) {
    // Dashboard
    .top-list-box {
        .top-btn {
            &> ul {
                margin: 0 -10px!important;
                &> li {
                    width: 50%;
                    padding: 0 10px;
                    .btn {
                        width: 100%;
                    }
                    &+ li {
                        padding-left: 10px;
                    }
                }
            }
        }
    }

    // Panel
    .drawer-form-lg {
        width: calc(100vw - 30px) !important;
    }
    .panel {
        padding: 10px;
        &.panel-create {
            padding-top: 70px;
        }
        .panel-content {
            padding-top: 15px;
            .content-inner {
                padding-bottom: 180px;
            }
            .step-list {
                margin: 0 0 15px 0;
                min-height: 86px;
                ul {
                    display: block;
                    text-align: left;
                    overflow: hidden;
                    &:before {
                        display: none;
                        left: 0;
                        width: 100%;
                    }
                    li {
                        padding: 0 5px !important;
                        width: 33.3%;
                        float: left;
                    }
                }
            }
            .step-content {
                padding-left: 5px;
                padding-right: 10px;
            }
            .group {
                h4 {
                    padding-bottom: 5px;
                }
                .group-item {
                    &.pt20 {
                        padding-top: 0 !important;
                    }
                }
                .list-item {
                    padding: 10px;
                }
            }
            .btn-list {
                padding: 0 10px;
                li {
                    .btn {
                        min-width: 80px !important;
                        padding: 0 7px;
                        height: 32px;
                        line-height: 30px;
                        font-size: $f14;
                        width: 80px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    &+ li {
                        padding-left: 10px;
                    }
                }
            }
        }
    }

    // Date picker
    .react-datepicker-popper {
        .react-datepicker {
            .react-datepicker__day-name {
                width: 25px;
            }
            .react-datepicker__day {
                width: 25px;
                height: 25px;
                line-height: 25px;
            }
        }
    }
 }

 @media screen and (max-width: 575px) {
    .panel {
        .panel-content {
            .step-content {
                .row {
                    &> [class*='col-'] {
                        &+ [class*='col-'] {
                            margin-top: 15px;
                        }
                    }
                }
            }
            .tab-create {
                .nav-tabs {
                    margin: 0 -5px;
                    .nav-item {
                        width: 33.3%;
                        padding: 0 5px;
                        margin: 0;
                        &+ .nav-item {
                            padding: 0 5px;
                        }
                    }
                }
                .label-tab {
                    float: none;
                    width: 100%;
                    margin-bottom: 10px;
                    &+ .nav-tabs {
                        float: none;
                        width: 100%;
                    }
                }
                .tab-content {
                    .tab-pane {
                        .day-list {
                            ul {
                                margin-bottom: -10px;
                                li {
                                    width: 50px;
                                    margin-bottom: 10px;
                                    margin-right: 13px;
                                    label {
                                        input[type="checkbox"] {
                                            &+ span {
                                                width: 50px;
                                                height: 50px;
                                                line-height: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
 }


