$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;

/* @mixin */
@mixin radius($radius) {
  border-radius: $radius !important;
  -webkit-border-radius: $radius !important;
  -moz-border-radius: $radius !important;
}

$border: #e1e9ef;

/* Color */
$primary_color: #000;
$body_background: #f5f9fc;
$white: #fff;
$red: #f00;
$light_red: #ff2d5a;
$pink: #ff6984;
$dark_pink: #ac253d;
$color_hover: #ff6984;
$gray: #7f7f7f;
$gray_light: #7f7f7b;
$gray_mid: #7b90ac;
$gray_dark: #3d506a;
$dark_gray: #526d7b;
$black_blue: #3f4d5f;
$purple: #1b1c87;
$green: #00b54a;
$dark_violet: #1b1b8b;

/* Font size */
$f14: 13px;
$f12: 12px;
