.tooltip {
    .arrow {
        &:before {
            // border-color: $white !important;
        }
    }
}
.tooltip-inner {
    background-color: $white;
    -webkit-box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.4);
    box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.4);
    color: $dark_gray;
}
.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
    .arrow {
        &:before {
            border-left-color: $white;
        }
    }
}
.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
    .arrow {
        &:before {
            border-right-color: $white;
        }
    }
}
.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
    .arrow {
        &:before {
            border-top-color: $white;
        }
    }
}
.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
    .arrow {
        &:before {
            border-bottom-color: $white;
        }
    }
}
 