.table-manager {
  &.data-grid {
    padding: 30px 0;
  }

  .df-react-table {
    table {
      border-collapse: separate;
      border-spacing: 0 5px;
      position: relative;

      .table-body {
        tr {
          align-items: center;
        }
      }
    }
  }
}
