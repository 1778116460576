@import '../../../assets/style/variable';

.dashboard-table-manager {
  margin: 50px 0;

  .table-tabs {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    border-bottom: 2px solid $border;
    column-gap: 10px;

    .table-item {
      cursor: pointer;
      width: fit-content;
      padding: 12px 10px;
      font-size: $f14 + 2;
      color: $gray;
      border: none;
      background-color: transparent;

      &:hover {
        color: $pink;
      }

      &.active-table {
        color: $pink;
        font-weight: bold;
        position: relative;
        margin-bottom: -1px;

        &:before {
          background: $pink;
          content: '';
          height: 4px;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -1px;
        }
      }
    }
  }

  .dashboard-table {
    min-height: 639px;

    .table-manager.data-grid {
      padding: 0;

      .table-manager-secondary-text {
        font-weight: bold;
        font-size: 12px;

        &.up {
          color: $green;
        }

        &.down {
          color: $light_red;
        }
      }

      .bot-grid {
        padding: 20px 0 0;
      }
    }
  }
}
