@import '../../../assets/style/variable';

.table-columns-info-box {
  position: fixed;
  min-width: 600px;
  width: 70vw;
  height: 80vh;
  right: 18vh;
  bottom: 4vh;
  background-color: #fff;
  z-index: 100;
  padding: 15px;
  -webkit-box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
  box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
  border-radius: 4px;
  color: #212529;
  display: grid;
  box-sizing: content-box;
  overflow-y: auto;
  overflow-x: hidden;

  .table-info-title {
    border-bottom: 2px solid $border;
    padding-bottom: 0px;
    display: grid;
    grid-template-columns: 1fr auto;
    height: fit-content;

    h6,
    p {
      margin-bottom: 0;
    }

    .btn-close {
      position: relative;
      cursor: pointer;
      width: fit-content;

      span {
        background: url('../../../assets/images/icons/ico_close.svg') no-repeat
          center center;
        display: block;
        width: 20px;
        height: 20px;
        -webkit-transition: color 0.15s ease, -webkit-transform 0.3s ease;
        -o-transition: color 0.15s ease, transform 0.3s ease;
        transition: color 0.15s ease, transform 0.3s ease,
          -webkit-transform 0.3s ease;

        &:hover {
          background: url('../../../assets/images/icons/ico_close_o.svg') no-repeat
            center center;
          transform: rotate(90deg);
        }
      }
    }
  }

  .table-info-subtitle {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .table-info-tabs {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    border-bottom: 2px solid $border;
    column-gap: 10px;
    width: max-content;

    .table-info-item {
      cursor: pointer;
      width: fit-content;
      padding: 0 10px;
      font-size: $f14 + 2;
      color: #212529;
      border: none;
      background-color: transparent;

      &:hover {
        color: $pink;
      }

      &.active-type {
        color: $pink;
        font-weight: bold;
        position: relative;
        margin-bottom: -1px;

        &:before {
          background: $pink;
          content: '';
          height: 4px;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -1px;
        }
      }
    }
  }

  .table-info-url {
    border: 1px solid $border;
    background: #fdebee;
    border-radius: 4px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 10px;
    height: max-content;
    word-break: break-word;
    white-space: pre-wrap;
  }

  .table-info-params {
    height: 1fr;

    table {
      width: 100%;

      thead {
        th {
          padding: 0;
          flex: none !important;
        }
      }

      tbody {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 38vh;
        background-color: $body_background;

        tr {
          display: block !important;
          word-break: break-word;
          white-space: pre-wrap;

          td {
            padding: 0;

            label {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .table-info-p-detail {
    margin-bottom: 10px;
  }

  .table-info-example {
    margin-bottom: 4px;
  }

  .table-info-example-url {
    margin-bottom: 10px;
  }

  .table-info-download {
    margin-bottom: 20px;
    align-items: center;

    button {
      width: fit-content;
      height: fit-content;
    }
  }
}
