.dashboard-filter-form {
  display: grid;
  grid-template-rows: auto 1fr auto;

  .panel-title {
    .btn-close {
      width: fit-content;
    }

    h4 {
      margin-bottom: 20px;
    }
  }

  .dashboard-filter-container.panel-content {
    overflow: hidden;
    height: 100%;

    .dashboard-filter-form-content {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .dashboard-filter-form-last-element {
        padding: 15px 0 150px 0;
      }
    }
  }

  .panel-footer {
    justify-self: center;
    padding: 20px 0;

    .btn {
      min-width: 150px!important;

      &.btn-pink-light {
        border-color: #FF6984;
      }
    }
  }
}
