@import '../../../assets/style/variable';

.donut-chart-box {
  .donut-chart {
    display: grid;
    grid-template-areas: "head" "chart";

    .donut-chart-head {
      display: grid;
      grid-template-areas: "left right";
      border-bottom: 1px solid $border;
      padding: 0 15px;
      align-items: center;

      .dropdown {
        width: fit-content;
        line-height: inherit;

        img {
          height: 6px;
          position: relative;
          top: 1px;
          padding-left: 5px;
        }

        span {
          padding: 0;
          font-size: $f14 + 4;
          color: $black_blue;
        }
      }

      .donut-chart-head-left {
        grid-area: left;

        .dropdown img {
          padding-left: 5px;
        }
      }

      .donut-chart-head-right {
        grid-area: right;
        justify-self: end;

        .dropdown img {
          padding-right: 5px;
        }
      }
    }

    .donut-chart-chart {
      grid-area: chart;

      .donut-chart-loading {
        height: 300px;
      }
    }
  }
}
