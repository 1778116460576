@import '../../../assets/style/variable';

.table-columns-control {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 90;

  .table-columns-control-view {
    margin: 0;
    text-align: right;
    padding: 0;

    span {
      border: 1px solid $border;
      display: inline-block;
      background: $white url('../../../assets/images/icons/ico_view.svg') no-repeat center center;
      @include radius(4px);
      width: 26px;
      height: 26px;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: $white url('../../../assets/images/icons/ico_view_o.svg') no-repeat center center;
      }
    }
  }

  .table-columns-control-list {
    padding: 0;

    ul {
      background: $white;
      list-style: none;
      margin: 0;
      padding: 10px;
      border-color: $border;
      -webkit-box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
      box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
      width: 100%;

      @include radius(4px);
      position: absolute;
      right: 0;
      top: 38px;
      z-index: 100;
      margin-top: 5px;
      min-width: 200px;

      li {
        color: $black_blue;
        font-size: $f14;

        label {
          color: $black_blue;
          cursor: pointer;
          margin: 0;
        }

        &+ li {
          padding-top: 10px;
        }
      }

      &:before {
        position: absolute;
        top: -8px;
        right: 5px;
        display: inline-block;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-bottom-color: transparent;
        content: '';
      }

      &:after {
        position: absolute;
        top: -10px;
        right: 6px;
        display: inline-block;
        border-right: 7px solid transparent;
        border-bottom: 13px solid $white;
        border-left: 7px solid transparent;
        content: '';
      }
    }
  }
}
