@import '../../assets/style/common.scss';

#Header {
  .header-block {
    .add-new {
      .dropdown-toggle {
        &::after {
          display: none;
        }
      }
    }
  }

  .user-notifications {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 40px;
    position: relative;
    cursor: pointer;

    .notification-bell {
      &:hover {
        opacity: 0.7;
      }
    }

    .notifications-count {
      position: absolute;
      bottom: 16px;
      left: 15px;
      color: $white;
      font-weight: bold;
      background-color: $dark_violet;
      border-radius: 50%;
      padding: 4px 8px;
      font-size: 12px;
      text-align: center;
      box-sizing: content-box;
    }

    .notifications-container {
      position: absolute;
      top: 35px;
      right: 10px;
      background-color: $white;
      width: 350px;
      height: auto;
      max-height: 350px;
      cursor: default;
      overflow: auto;
      box-shadow: 0 0 10px 0 rgba(58, 75, 207, 0.08);
    }
  }
}
