.endpoints-demand-feed {
  max-height: 120px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    -webkit-border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 20px;
    -webkit-border-radius: 20px;
  }

  .demand-feed-box {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    column-gap: 5px;
    margin-top: 10px;

    &:nth-child(1) {
      margin-top: 0;
    }

    .endpoints-demand-title {
      cursor: pointer;
      width: fit-content;
      text-decoration: dashed underline;
    }

    div.form-group {
      margin: 0 5px;
    }
  }
}
