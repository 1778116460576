@import '../../../assets/style/variable';

.table-search {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "text fields";
  margin-bottom: 30px;

  .t-search-text {
    grid-area: text;
    font-size: 22px;
    color: black;
    margin: 0;
    align-self: center;

    span {
      color: $gray;
      font-size: 16px;
      padding-left: 5px;
    }
  }

  .t-search-fields {
    grid-area: fields;
    justify-self: end;
    display: grid;
    grid-auto-flow: column;
    align-items: center;

    .t-search-fields-item {
      margin-left: 40px;

      &:nth-child(1) {
        margin-left: 0;
      }

      .t-search-fields-item-title {
        display: inline-block;
        margin-right: 10px;
        color: $gray;
      }

      .t-search-fields-item-field {
        display: inline-block;

        .react-datepicker__tab-loop {
          display: inline-block;
        }

        .react-datepicker-wrapper:nth-child(1) {
          margin-right: 10px;
        }
      }

      .btn-search {
        border: 1px solid $border;
        background-color: white;
        border-radius: 50%;
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .search-text-container {
        width: 200px;
        position: relative;

        .search-text-input {
          padding-left: 35px;
        }

        .search-icon {
          position: absolute;
          left: 14px;
          top: 10.5px;
        }
      }

      .table-creation-to-date {
        left: -60px !important;
      }
    }
  }
}
