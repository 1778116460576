@import "../../assets/style/variable";

.rules-wizard {
  &.panel.panel-create {
    background-color: $body_background;
    position: unset;
    top: unset;
    right: unset;
    height: 100%;
    width: 100%;
    padding: 40px 0 0 0;

    .panel-title {
      padding: 0 70px 0 120px;
    }
  }

  display: grid;
  grid-template-rows: auto 1fr auto;

  .panel-title {
    display: grid;
    grid-template-areas: "title close";
    grid-template-columns: 1fr 50px;

    .rules-wizard-title {
      grid-area: title;

      &.form-group {
        margin-bottom: 5px;
      }
    }

    .btn-close {
      grid-area: close;
      width: fit-content;
      justify-self: end;
    }
  }

  .rules-wizard-container.panel-content {
    overflow: hidden;
    height: 100%;
    padding: 15px 100px 0 100px;

    .rules-wizard-form-content {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 20px;

      .rules-do-when-container {
        margin-top: 25px;
      }

      .rules-action-container {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 10px;

        .rules-action-has-data {
          align-self: end;
          margin-bottom: 3px;

          .wizard-input-container {
            input {
              border-color: hsl(0,0%,80%);
              border-style: solid;
              border-width: 1px;
            }
          }
        }
      }

      .rules-radio-container {
        display: grid;
        grid-auto-flow: column;
        justify-items: center;
        margin: 10px 0;

        input {
          margin-left: 10px;
        }
      }

      .filters-box {
        margin-top: 10px;

        &.last-filter-box {
          .group-item .row-item .row {
            .filter-metrics-value {
              .wizard-input-container {
                margin-top: 2px;
              }
            }
          }
        }
      }

      .rules-frequency-container {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-areas: "title title" "interval custom";
        grid-column-gap: 10px;
        margin-top: 20px;

        h4 {
          grid-area: title;
        }

        .rules-frequency-interval {
          grid-area: interval;
        }

        .rules-frequency-custom {
          grid-area: custom;
          align-self: center;
          margin-bottom: 1px;

          input {
            border: 1px solid hsl(0, 0%, 80%);
          }

          .react-datepicker-popper .react-datepicker .react-datepicker__header {
            display: none;
          }
        }
      }

      .rules-frequency-days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        padding-bottom: 100px;
        margin-top: 30px;

        .rules-frequency-day {
          border-radius: 50%;
          padding: 15px;
          width: 58px;
          justify-self: center;
          text-align: center;
          align-self: center;
          border: 1px solid $pink;
          cursor: pointer;

          &.day-selected {
            background-color: $pink;
            color: $white;
          }
        }
      }
    }
  }

  .panel-footer {
    text-align: end;
    padding: 15px 150px 15px 100px;
    background-color: $white;
  }
}
