@import "../../assets/style/variable";

.reports-page {
  .reports-page-top-container {
    .insights-generator {
      display: inline-block;
      width: 160px;
    }

    .reports-page-list-reports {
      position: relative;
      display: inline-block;

      .list-reports-trigger {
        margin-left: 20px;
      }

      .list-reports-box {
        position: absolute;
        top: 40px;
        left: 0;
        background-color: $white;
        box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.16);
        z-index: 1;
        padding: 5px 0;
        overflow: auto;
        max-height: 250px;
        min-width: max-content;

        .list-reports-item {
          display: grid;
          grid-template-areas: "title edit delete";
          grid-template-columns: 1fr auto auto;
          column-gap: 6px;
          padding: 10px;
          max-width: 300px;
          border-top: 1px solid $border;

          &:nth-child(1) {
            border-top: none;
          }

          .reports-item-title {
            grid-area: title;
            cursor: pointer;
          }

          .report-item-edit {
            grid-area: edit;
          }

          .report-item-delete {
            grid-area: delete;
          }

          .report-item-edit, .report-item-delete {
            border: none;
            padding: 0;
            margin: 0 7px;
            width: 16px;
            height: 16px;

            img {
              display: block;
              visibility: visible;
              background-color: $white;

              &.img-hovered {
                display: none;
                visibility: hidden;
              }
            }

            &:hover {
              img {
                display: none;
                visibility: hidden;

                &.img-hovered {
                  display: block;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }

    button {
      height: fit-content;
      align-self: center;
    }
  }

  .reports-page-table {
    .table-manager.data-grid {
      padding: 0;

      .df-react-table .table-search {
        margin-bottom: 0;
      }
    }
  }
}
