@import "../../../assets/style/variable.scss";

.top-list-box {
  min-height: 153px;

  .box-item {
    margin-bottom: 30px;
  }

  .box-inner {
    background: $white;
    -webkit-box-shadow: 0 0 10px 0 rgba(58, 75, 207, 0.08);
    box-shadow: 0 0 10px 0 rgba(58, 75, 207, 0.08);

    @include radius(4px);
    padding: 10px;

    p {
      margin: 0;
    }

    .box-name {
      color: $black_blue;
      font-size: $f14 + 2;
    }

    .curency-line {
      font-size: $f14 + 8;
      font-weight: 700;
      color: $purple;
      padding: 5px 0 0 0;

      span {
        font-size: $f14 - 2;
        font-weight: normal;

        &.up {
          color: $green;
        }

        &.down {
          color: $light_red;
        }
      }

      .curency-satus {
        float: left;
      }

      .sparkline {
        float: right;
        width: 80px;
      }
    }

    .list-bot {
      list-style: none;
      margin: 0;
      padding: 5px 0 0 0;

      li {
        float: left;
        padding-right: 5px;
        width: 33.3%;

        p {
          font-weight: 700;

          .span-name {
            display: block;
            font-size: $f14 - 2;
            color: $gray;
            text-transform: uppercase;
            font-weight: normal;
            margin-top: -3px;
          }

          .span-value {
            &.up {
              color: $green;
            }

            &.down {
              color: $light_red;
            }
          }
        }
      }
    }
  }

  .graph-container {
    position: relative;
  }
}
