.action-right .row-item {
  position: relative;
  padding-right: 40px;
}

.action-right .action-icon.delete-item {
  color: #7F7F7F;
  font-size: 18px;
  bottom: 5px;
  right: 14px;
  position: absolute;
  cursor: pointer;
}

.action-right .action-icon.disable {
  opacity: 0.7;
}

.action-right .action-icon.delete-item:hover {
  color: red;
}
