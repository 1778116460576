@import '../../assets/style/variable';

.timeline-log-container {
  .timeline-log-filters {
    .timeline-log-interval-filter {
      position: relative;

      .btn.btn-gray.dropdown-toggle {
        height: 100%;

        img {
          display: inline-block;
          vertical-align: middle;
          height: 21px;

          &.img-active {
            display: none;
            visibility: hidden;
          }

          &+span {
            padding-left: 7px;
          }
        }

        &:hover {
          background: none;
          color: $pink;

          img {
            display: none;
            visibility: hidden;

            &.img-active {
              display: inline-block;
              visibility: visible;
            }
          }
        }
      }

      .dashboard-dropdown-menu {
        left: 50px;

        .custom-filter {
          .box-custom {
            display: none;
            padding: 5px 10px 7px;

            .box-item {
              .label {
                margin: 0;
                color: #7f7f7f;
              }
            }

            .box-item + .box-item {
              margin-top: 5px;
            }

            .apply {
              margin: 10px 0 0 0;
              text-align: right;
            }
          }

          .show-box + .box-custom {
            display: block;
          }
        }
      }
    }
  }
}
