.landing {
  background-size: cover;
  background: url('../../assets/images/landing_bg.jpg') no-repeat center;
  height: 100vh;
}

.landing2 {
  background-image: url('../../assets/images/home-bg.png');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 100vh;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::placeholder {
  color: #bbb !important;
}
