.endpoint-demands-form {
  .endpoint-demand {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-areas: "title active remove";
    margin: 10px 0 25px 0;
    align-items: center;
    column-gap: 15px;

    .demand-title {
      grid-area: title;
      margin-bottom: 0;
    }

    .form-group {
      grid-area: active;
      margin-bottom: 0;
    }

    .demand-remove {
      grid-area: remove;
      justify-self: end;

      color: #7F7F7F;
      font-size: 18px;
      cursor: pointer;
      margin-right: 20px;

      &:hover {
        color: red;
      }
    }
  }
}
