@import "../../assets/style/variable";

.settings-side-bar {
  height: 100%;
  border-right: 1px solid $border;
  color: $primary_color;
  padding-top: 20px;
  padding-right: 10px;

  .settings-row {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 15px;
    row-gap: 10px;
    padding: 5px 10px;
    cursor: pointer;
    margin-bottom: 10px;

    &.no-cursor {
      cursor: default;
      margin-bottom: 20px;

      &:hover {
        color: $primary_color;
      }
    }

    &:hover {
      color: $pink;
    }

    &.active {
      color: $pink;

      .settings-row-icon {
        img {
          visibility: hidden;
          display: none;

          &.img-active {
            display: inline-block;
            visibility: visible;
          }
        }
      }
    }

    &:hover {
      .settings-row-icon {
        background: none;
        color: $pink;

        img {
          display: none;
          visibility: hidden;

          &.img-active {
            display: inline-block;
            visibility: visible;
          }
        }
      }
    }

    .settings-row-icon {
      align-self: center;
      width: fit-content;

      img {
        display: inline-block;
        vertical-align: middle;
        height: 21px;

        &.img-active {
          display: none;
        }
      }
    }
  }
}
