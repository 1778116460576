.reports-date-picker-container {
  &.top-btn > ul {
    text-align: left;
  }

  &.top-btn > ul > li.dropdown {
    width: 100%;

    .reports-date-picker {
      .dropdown-toggle {
        width: 100%;
        display: grid;
        grid-template-areas: "label icon";
        grid-template-columns: 1fr auto;
        text-align: start;
        color: hsl(0, 0%, 20%);

        span {
          grid-area: label;
          padding: 0;
        }

        div {
          grid-area: icon
        }
      }

      .custom-filter .box-custom .box-item .box-date img.date-picker-icon {
        position: absolute;
        top: 50%;
        right: 10px;
      }

      ul li a {
        cursor: pointer;
      }
    }
  }
}
