.minisite {
  body {
    font-family: "Barlow", sans-serif;
  }

  .home {
    background-image: url(../images/home-bg.png);
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
  }

  .privacy {
    background-image: url(../images/privacy-bg.png);
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
  }

  .login {
    background-image: url(../images/login-bg.png);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  .foot-bg {
    background-image: url(../images/footer-bg.png);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding: 12rem 0;
  }

  #navigation {
    max-width: 1440px;
    margin: 0 auto;
  }

  /*.nav-item {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 1.5rem;
}*/

  .nav-link {
    padding: 0.25rem 1rem 0.45rem 1rem;
  }

  .top-header {
    padding: 21px 0;
  }

  .hero-row {
    padding: 50px 0;
  }

  .mobile-hero-background,
  .hero-txt {
    margin-bottom: 30px;
  }

  .signup .mobile-hero-background {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px;
    border-radius: 10px;
  }

  .login .mobile-hero-background {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px;
    border-radius: 10px;
  }

  .navbar {
    background-color: rgba(255, 255, 255, 1);
  }

  .nav-holder {
    box-shadow: 0px 7px 20px 0px rgba(58, 75, 207, 0.14);
    height: 65px;
    background-color: #fff;
    top: -1px;
  }

  .navbar-logo {
    width: 168px;
    height: auto;
  }

  h1 {
    font-weight: bold;
    font-size: 2.5rem;
  }

  h2 {
    font-weight: bold;
    font-size: 1.9rem;
  }

  h3 {
    font-weight: bold;
    font-size: 1.875rem;
  }

  h4 {
    font-weight: bold;
    font-size: 1.5625rem;
  }

  h5 {
    font-size: 1.125rem;
  }

  p {
    font-size: 1.25rem;
  }

  .btn-primary {
    color: #fff;
    background-color: #ff6984;
    border-color: #ff6984;
  }

  .btn-signup {
    text-align: center;
  }

  .btn {
    //height: 50px !important;
    padding: 0.565rem 2.75rem 0.765rem 2.75rem;
    //font-size: 1rem;
    //font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 1.5rem;
  }

  .btn2 {
    padding: 0.565rem 1.75rem 0.765rem 1.75rem;
  }

  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary:hover {
    border-color: inherit !important;
    -webkit-box-shadow: none !important;
    outline: 0 !important;
    box-shadow: none !important;
    background-color: #e55e77 !important;
    border-color: #e55e77 !important;
  }

  .mt--1 {
    margin-top: -4rem;
  }

  .services-block {
    padding: 50px 0;
  }

  .be-flex {
    display: flex;
  }

  .sign-shadow {
    -webkit-box-shadow: 0px 7px 20px 0px rgba(58, 75, 207, 0.14);
    -moz-box-shadow: 0px 7px 20px 0px rgba(58, 75, 207, 0.14);
    box-shadow: 0px 7px 20px 0px rgba(58, 75, 207, 0.14);
    flex-grow: 1;
  }

  .cta-descrip {
    color: #1b59cd;
    font-weight: bold;
    font-size: 4rem;
    text-align: center;
  }

  .weare-block {
    padding-top: 870px;
    padding-bottom: 150px;
    background-image: url(../images/weare-bg.png);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }

  .weare-start {
    color: rgba(255, 255, 255, 0.5);
    font-weight: bold;
    font-size: 0.875rem;
  }

  .weare-descrip {
    color: #ffffff;
    font-size: 1.4rem;
  }

  .whydf-block {
    padding: 100px 0;
  }

  .whydf-start {
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    font-size: 0.875rem;
  }

  .whydf-descrip {
    color: #000000;
    font-size: 1.75rem;
  }

  .whydf-head {
    color: #1b59cd;
    font-weight: bold;
    font-size: 3rem;
    line-height: 3rem;
  }

  .contact-block {
    padding: 100px 0;
    background-image: url(../images/contact-bg.png);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }

  .contact-start {
    color: rgba(255, 255, 255, 0.5);
    font-weight: bold;
    font-size: 0.875rem;
  }

  .contact-descrip {
    color: #ffffff;
    font-size: 1.75rem;
  }

  .contact-head {
    color: #ffffff;
    font-weight: bold;
    font-size: 3rem;
    line-height: 2rem;
  }

  .signup .form-control,
  .login .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1.0625rem;
    line-height: 1.5;
    color: rgba(0, 0, 0, 1);
    background-color: transparent;
    background-clip: padding-box;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0 !important;
  }

  .signup .form-control::-webkit-input-placeholder,
  .login .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(0, 0, 0, 0.25);
  }

  .signup .form-control::-moz-placeholder,
  .login .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.25);
  }

  .signup .form-control:-ms-input-placeholder,
  .login .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(0, 0, 0, 0.25);
  }

  .signup .form-control:-moz-placeholder,
  .login .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.25);
  }

  .signup .form-control:hover::-webkit-input-placeholder,
  .login .form-control:hover::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(0, 0, 0, 0.25);
  }

  .signup .form-control:hover::-moz-placeholder,
  .login .form-control:hover::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.25);
  }

  .signup .form-control:hover:-ms-input-placeholder,
  .login .form-control:hover:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(0, 0, 0, 0.25);
  }

  .signup .form-control:hover:-moz-placeholder,
  .login .form-control:hover:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.25);
  }

  .signup .form-control:focus,
  .login .form-control:focus,
  .signup .form-control:active,
  .login .form-control:active,
  .signup .form-control:hover,
  .login .form-control:hover {
    border-color: inherit;
    -webkit-box-shadow: none;
    outline: 0;
    box-shadow: none;
  }

  .home .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1.5rem;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.25);
    background-color: transparent;
    background-clip: padding-box;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 0px !important;
  }

  .home .form-control:active {
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    border-radius: 0;
  }

  .home .form-control:focus {
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    border-radius: 0;
  }

  .home .form-control:hover {
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    border-radius: 0;
  }

  .home .form-control:hover::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: white;
  }

  .home .form-control:hover::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
  }

  .home .form-control:hover:-ms-input-placeholder {
    /* IE 10+ */
    color: white;
  }

  .home .form-control:hover:-moz-placeholder {
    /* Firefox 18- */
    color: white;
  }

  .home .form-control:focus,
  .form-control:active,
  .form-control:hover {
    border-color: inherit;
    -webkit-box-shadow: none;
    outline: 0;
    box-shadow: none;
  }

  .bot-footer {
    text-align: center;
  }

  .signin .bot-footer,
  .login .bot-footer {
    text-align: center;
  }

  .footer-logo {
    width: 148px;
    height: auto;
  }

  .xsm-font {
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.8125rem;
  }

  .sm-font {
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.875rem;
  }

  .sm-font a:link {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.45);
    text-decoration: underline;
  }

  .sm-font a:visited {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.45);
  }

  .sm-font a:hover {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.45);
  }

  .sm-font a:active {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.45);
  }

  .letter-spa {
    letter-spacing: 0.2rem;
  }

  .font-faded {
    color: rgba(0, 0, 0, 0.5);
  }

  .p-privacy {
    font-size: 1.0625rem;
  }

  ul {
    list-style: none;
  }

  .row-login {
    text-align: center;
  }

  .btn-log {
    padding: 0.675rem 6.75rem;
  }

  .label-pos {
    padding-top: 0.5rem !important;
    position: absolute;
    right: 2rem;
  }

  meter {
    /* Reset the default appearance */

    margin: 0 auto 1.25rem;
    width: 100%;
    height: 0.15rem;

    /* Applicable only to Firefox */
    background: none;
    background-color: rgba(0, 0, 0, 0.1);
  }

  meter::-webkit-meter-bar {
    background: none;
    background-color: rgba(0, 0, 0, 0.1);
  }

  /* Webkit based browsers */
  meter[value="1"]::-webkit-meter-optimum-value {
    background: #f61b1b;
  }

  meter[value="2"]::-webkit-meter-optimum-value {
    background: #fe8300;
  }

  meter[value="3"]::-webkit-meter-optimum-value {
    background: #f6ee1b;
  }

  meter[value="4"]::-webkit-meter-optimum-value {
    background: #55c07a;
  }

  /* Gecko based browsers */
  meter[value="1"]::-moz-meter-bar {
    background: #f61b1b;
  }

  meter[value="2"]::-moz-meter-bar {
    background: #fe8300;
  }

  meter[value="3"]::-moz-meter-bar {
    background: #f6ee1b;
  }

  meter[value="4"]::-moz-meter-bar {
    background: #55c07a;
  }

  .meter-font {
    position: absolute;
    right: 1rem;
    margin-top: -20px;
  }

  @media (min-width: 1400px) {
    .container {
      max-width: 1200px;
    }

    header {
      max-width: 1440px;
      margin: 0 auto;
    }
  }

  @media (max-width: 1339px) {
    .hero-image {
      max-width: 100%;
      height: auto;
    }

    .home {
      background-size: auto;
    }

    .signin .bot-footer,
    .login .bot-footer {
      position: relative;
    }
  }

  @media (max-width: 1199px) {
    .btn-log {
      padding: 0.675rem 4.75rem;
    }
  }

  @media (max-width: 991px) {
    .mobile-hero-background {
      background-color: rgba(255, 255, 255, 0.5);
      padding: 1rem;
      border-radius: 1rem;
    }

    .weare-block {
      padding-top: 400px;
      padding-bottom: 150px;
    }

    .btn-log {
      padding: 0.675rem 2.75rem;
    }
  }

  @media (max-width: 767px) {
    .mobile-hero-background {
      margin: 2rem;
    }

    .sign-shadow {
      margin-bottom: 2rem !important;
    }

    .cta-descrip {
      font-size: 3rem;
    }

    .col-md-6 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }

    .services-block {
      padding: 0;
    }

    .contact-block {
      padding: 200px 0 100px 0;
    }

    h2 {
      font-size: 1.75rem;
    }

    .btn-log {
      padding: 0.675rem 2.75rem;
    }
  }
}
