@import "../../../assets/style/variable";

.table-columns-action-box {
  position: relative;

  .table-actions-box {
    position: absolute;
    top: 30px;
    left: 0;
    background-color: #fff;
    padding: 10px;
    z-index: 1;
    display: grid;
    row-gap: 20px;
    border: 1px solid rgba(0,0,0,.2);
    width: max-content;
  }

  .table-action-trigger {
    position: relative;
    width: fit-content;
    cursor: pointer;

    img {
      display: inline-block;
      vertical-align: middle;
      height: 21px;

      &.img-active {
        display: none;
      }
    }

    &:hover {
      background: none;
      color: $pink;

      img {
        display: none;
        visibility: hidden;

        &.img-active {
          display: inline-block;
          visibility: visible;
        }
      }
    }

    &.the-action {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 10px;
      min-width: max-content;
    }
  }
}

ul.table-columns-action {
  text-align: left;
}

.action-btn {
  li {
    &+ li {
      padding-left: 10px !important;
    }
  }

  .table-action-btn {
    .info-img {
      height: 18px;
      width: 18px;
    }

    .fa-icons {
      font-size: 16px;
      color: gray;
    }

    &:hover {
      .fa-icons {
        color: $pink;
      }
    }
  }
}
