@import '../../assets/style/common.scss';

.notification-box {
  .notification-unread {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 10px 20px;
    border-bottom: 1px solid $border;

    .notification-unread-button {
      color: $pink;
      cursor: pointer;
    }
  }

  .notification-item {
    display: grid;
    grid-template-areas: "icon data" "icon time";
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
    column-gap: 15px;
    padding: 10px 15px;
    border-bottom: 1px solid $border;

    &:nth-last-child(1) {
      border-bottom: none;
    }

    .notification-data {
      grid-area: data;

      &.unread {
        font-weight: bold;
      }
    }

    .notification-time {
      grid-area: time;
      display: grid;
      grid-template-columns: 1fr auto;

      .notification-time-action {
        cursor: pointer;
        color: $pink;
      }
    }

    .notification-icon {
      grid-area: icon;
      width: 24px;
      height: 24px;
      align-self: center;
    }
  }
}
