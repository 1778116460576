@import '../../../assets/style/variable';

.table-pagination {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "select text button";
  column-gap: 10px;

  .t-p-select {
    grid-area: select;
  }

  .t-p-text {
    grid-area: text;
    color: $gray;
    align-self: center;
  }

  .t-p-button-box {
    grid-area: button;
    justify-self: end;
    @include radius(4px);
    overflow: hidden;
    border: 1px solid $border;

    .t-p-button {
      height: 36px;
      line-height: 36px;
      padding: 0 5px;
      margin: 0;
      outline: none;
      border: none $border;

      &.active {
        color: $white;
        background: $purple;
        font-weight: 700;
      }

      &.other {
        border-left: 1px solid $border;
        width: 36px;
        text-align: center;
        padding: 0;
      }

      &.next {
        border-left: 1px solid $border;
      }

      &:active,
      &:focus {
        outline: none;
        border-color: $border;
      }
    }
  }
}
