@import "../../assets/style/variable.scss";

.react-datepicker-popper {
  z-index: 500;
}

.block-filter {
  position: fixed;
  right: -505px;
  top: 62px;
  background: $white;
  height: calc(100vh - 64px);
  z-index: 10;
  width: 500px;
  -webkit-box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.14);
  box-shadow: 0 0 20px 0 rgba(58, 75, 207, 0.14);
  padding: 15px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  text-align: left;

  &.show-filter {
    -webkit-transform: translate(-500px, 0);
    -ms-transform: translate(-500px, 0);
    transform: translate(-500px, 0);
  }
}


.block-chart {
  margin-bottom: -30px;

  .block-inner {
    background: $white;
    -webkit-box-shadow: 0 0 10px 0 rgba(41, 138, 255, 0.13);
    box-shadow: 0 0 10px 0 rgba(41, 138, 255, 0.13);
    @include radius(4px);
    height: 100%;

    .block-head {
      border-bottom: 1px solid $border;
      padding: 0 15px;

      .block-head-left {
        float: left;
      }

      .dropdown {
        line-height: inherit;

        span {
          padding: 0;
          font-size: $f14 + 4;
          color: $black_blue;
        }
      }

      .dropdown-toggle {
        padding: 8px 0;
        line-height: inherit;
        height: auto;
        border: none;

        &:after {
          display: none;
        }

        img {
          height: 6px;
          position: relative;
          top: 1px;
          padding-left: 5px;
        }
      }

      .nav-tabs {
        border: none;

        li {
          a {
            padding: 0;
            font-size: $f14;

            &.active {
              &:before {
                display: none;
              }
            }
          }

          & + li {
            padding-left: 30px;
          }
        }
      }

      .block-head-right {
        float: right;
        padding: 12px 0 0 0;
      }
    }

    .block-content {
      padding: 10px 5px 0 5px;

      .tab-content {
        .tab-pane {
          padding: 0;
          min-height: 280px;
        }
      }
    }
  }

  .block-item {
    padding-bottom: 0;
    margin-bottom: 30px;
  }
}

.dashboard-react-table {
  .rt-thead {
    visibility: hidden;
  }
}

@media screen and (min-width: 1199px) {
  .top-list-box {
    &.5-col {
      .box-item {
        width: 20%;
        flex: inherit;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .block-chart {
    margin-bottom: 0;
  }
}

.row-item {
  position: relative;
  margin-bottom: 10px;

  &.has-delete {
    padding-right: 40px;

    .action-icon {
      position: absolute;
      cursor: pointer;
      color: #7F7F7F;
      font-size: 18px;
      bottom: 5px;
      right: 4px;

      &:hover {
        color: #f00;
      }
    }
  }

  .add-new {
    display: inline-block;
    cursor: pointer;
    color: #FF6984;

    span {
      display: inline-block;
      padding-left: 3px;
    }

    &:hover {
      color: #f00;
    }
  }
}
