.dashboard-date-pick-filter {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 15px;

  .dashboard-timezone-box {
    min-width: 250px;

    .react-select__single-value {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .btn.btn-gray.dropdown-toggle {
    padding: 8px 12px;
  }
}

.top-btn > ul > li.dropdown .dashboard-date-pick-filter {
  .custom-filter .box-custom .box-item .box-date img.date-picker-icon {
    position: absolute;
    top: 50%;
    right: 10px;
  }

  ul li a {
    cursor: pointer;
  }
}
