.confirm-popover-container {
  color: #3F4D5F;
  font-size: 1.2em;
  padding: 15px;

  .title {
    text-align: center;
  }

  .btn-container {
    display: flex;
    justify-content: space-around;

    .btn {
      padding: 5px 10px;
      font-size: 12px;
    }
  }
}


// We can overwrite them because popover warning for margin ?
//.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
//  margin-bottom: 0;
//}
//
//.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
//  margin-left: 0;
//}
//
//.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
//  margin-right: 0;
//}
//
//.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
//  margin: 0;
//}
//
//.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
//  margin-left: 0;
//}
//
//.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
//  margin: 0;
//}
//
//.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
//  margin-top: 0;
//}


