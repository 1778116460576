
.modal-crop-open {
    #Header {
        z-index: 0 !important;
    }
    .sidebar {
        z-index: 200;
    }
    .modal-crop {
        display: block !important;
    }
}
.modal-crop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
    .modal-backdrop-bg {
        background: rgba(0, 1, 46, .67);
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    .modal-crop-content {
        background: $white;
        position: relative;
        z-index: 10;
        max-width: 440px;
        width: 100%;
        @include radius(8px);
        box-shadow: 0 0 20px 0 rgba(58,75,207,0.14);
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 10px 20px 20px 20px;

        // .cropper-container {
        //     width: 400px !important;
        //     height: 400px !important;
        //     img {
        //         width: 100% !important;
        //         height: auto !important;
        //     }
        // }
        ul {
            list-style: none;
            margin: 20px 0 0 0;
            padding: 0;
            text-align: center;
            li {
                display: inline-block;
                vertical-align: middle;
                .btn {
                    display: block;
                    min-width: 150px!important;
                }
                &+ li {
                    padding-left: 15px;
                }
            }
        }
    }
    .modal-title {
        font-size: $f14 + 8;
        color: $primary_color;
        margin-bottom: 10px;
        position: relative;
        padding-right: 25px;
        span {
            position: absolute;
            right: 0;
            top: 0;
            color: $gray;
            font-weight: normal;
            cursor: pointer;
            z-index: 10;
            &:hover {
                color: $pink;
            }
        }
    }
}