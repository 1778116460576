@import "../../../assets/style/variable";

.wizard-steps-container {
  display: grid;
  grid-template-rows: auto 1fr;

  .wizard-steps-title {
    margin: 15px 0 30px 0;
    padding: 0 10px 0 30px;
    font-weight: bold;
    font-size: 15px;
  }

  .wizard-steps {
    padding: 10px 0;

    li {
      list-style: none;
      padding-left: 30px;
      display: grid;
      grid-template-columns: auto 1fr;

      &.wizard-step-switcher {
        cursor: pointer;
        width: max-content;
      }

      p {
        margin-left: 15px;
        margin-bottom: 0;
        margin-top: -5px;
        word-break: break-all;
      }

      .node {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        transition: all 700ms ease;

        &.activated {
          box-shadow: 0 0 3px 2px rgba(255, 205, 205, 1);
        }
      }

      .node, .divider {
        background-color: $gray_light;

        &.activated {
          background-color: $pink;
        }
      }

      .divider {
        height: 40px;
        width: 2px;
        margin-left: 4px;
        transition: all 800ms ease;
      }

      &.wizard-steps-divider {
        margin-top: -5px;
      }

      &.current-step {
        position: relative;

        .current-step-border {
          display: block;
          content: '';
          background: $pink;
          position: absolute;
          top: -10px;
          left: 0;
          height: 30px;
          width: 4px;
        }
      }
    }
  }
}
