.settings-container {
  display: grid;
  height: calc(100vh - 62px);
  grid-template-columns: auto 1fr;
  margin-top: -38px;
  margin-left: -32px;
  margin-right: -30px;

  @media screen and (max-width: 1199px) {
    margin-top: -24px;
    margin-left: -10px;
    width: 100%;
  }

  .settings-side-bar {
    height: 100%;
    overflow: hidden;
  }

  .settings-table-box {
    overflow: hidden;

    .settings-table {
      height: 100%;
      overflow-y: auto;
      padding: 50px 15px 0 15px;

      .table-manager.data-grid {
        padding-top: 0;
        padding-bottom: 70px;
      }
    }
  }
}
